import styled from 'styled-components/macro';
export const StepOneWrap = styled.div`
  padding-top: 7em;

  h3 {
    text-align: left;
  }

  @media (max-width: 478px) {
    padding-top: 3em;
  }
`;

export const InputsWrap = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 1.5em;
`;

export const InputGroupSingle = styled.div`
  margin-bottom: 3em;

  &:last-of-type {
    margin-bottom: 0;
  }

  .ant-select {
    width: 100%;
  }
`;

export const InputGroupDouble = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 3em;

  input {
    width: 49%;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  @media (max-width: 478px) {
    flex-direction: column;
    margin-bottom: 0;

    input {
      width: 100%;
      margin-bottom: 2.5em;
    }
  }
`;

export const StepTwoWrap = styled.div`
  padding-top: 7em;
  -webkit-animation: 0.45s fadeIn linear;
  -moz-animation: 0.45s fadeIn linear;
  animation: 0.45s fadeIn linear;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @media (max-width: 478px) {
    padding-top: 3.5em;
  }
`;

export const PaymentRadioGroupWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PaymentsRadioGroup = styled.div`
  display: flex;
  flex-direction: row;
  padding: 2.5em;
  margin-bottom: 4em;
  border: 1px solid #ededed;
  -webkit-border-radius: 1.5em;
  -moz-border-radius: 1.5em;
  border-radius: 1.5em;
  -webkit-box-shadow: #ededed 1em 1em 2em 0px;
  -moz-box-shadow: #ededed 1em 1em 2em 0px;
  box-shadow: #ededed 1em 1em 2em 0px;

  .ant-radio-wrapper {
    display: flex;
    align-items: center;
  }

  @media (max-width: 480px) {
    flex-direction: column;
  }
`;

export const PaymentImage = styled.img`
  width: auto;
  height: 2.5em;
`;

export const PaymentSpan = styled.span`
  font-size: 1.1em;
  font-weight: 300;
  margin-left: 1.5em;
`;

export const PaymentSelectWrap = styled.div`
  min-width: 15em;
  margin-left: 5em;

  .ant-select {
    width: 100%;
  }

  @media (max-width: 768px) {
    max-width: 100%;
    width: 100%;
    min-width: auto;
    margin-left: 3em;
  }

  @media (max-width: 500px) {
    margin-left: 1em;
  }

  @media (max-width: 480px) {
    margin-top: 2em;
  }
`;

export const MainViewComponent = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: -1.5em;
  margin-right: -1.5em;
  -webkit-animation: 0.45s fadeIn ease-in-out;
  -moz-animation: 0.45s fadeIn ease-in-out;
  animation: 0.45s fadeIn ease-in-out;
  padding-top: 7em;

  @media (max-width: 992px) {
    flex-direction: column;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    flex-wrap: wrap;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export const ItemsCTAWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 11.5% 0;
  flex-direction: column;

  button {
    margin-top: 24px;
  }
`;
