import React from 'react';
import { Container } from 'components/Common/Layout';
import { Button } from 'components/Common/Button';
import styled from 'styled-components/macro';

import { Paragraph } from 'components/Common/Text';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5em;

  button {
    width: 200px;
    margin-top: 2em;
  }
`;

const Heading = styled.h1`
  font-size: 4em;
  line-height: 1.2em;
  font-weight: 700;
  text-align: left;
  margin-bottom: 1em;

  @media (max-width: 768px) {
    text-align: center;
    font-size: 3em;
  }
`;

export const DowngradeAlreadyRequested: React.FC = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const redirectAccountSettings = () => {
    window.location.href = `${process.env.REACT_APP_DASHBOARD_URL}/${language}/account`;
  };
  return (
    <Container>
      <Wrapper>
        <Heading>{t('alreadyRequestedDowngrade')}</Heading>
        <Paragraph bold>{t('alreadtRequestedDowngradeText')}</Paragraph>
        <Button filled size="medium" onClick={redirectAccountSettings}>
          {t('accountSettings')}
        </Button>
      </Wrapper>
    </Container>
  );
};
