import {
  SET_SHIPPING_ID,
  SET_PAYMENT_TYPE,
  SET_ISSUER,
  SEND_SHIPPING_TYPE_START_SAGA,
  GET_SHIPPING_PRICE_START_SAGA,
  GET_PAYMENT_CHOICES_START_SAGA,
  SEND_ORDER_START_SAGA,
  CheckoutActionTypes,
  ISendOrderStartSaga,
} from 'store/actions/checkout-actions/checkout-action-types';
import { ProductOrderType } from 'lib/types';

export const setShippingType = (type: number) => {
  localStorage.setItem('shippingType', type.toString());
  return { type: SET_SHIPPING_ID, payload: type };
};
export const sendShipmentTypeChange = (
  stateCode: string,
  countryId: string
) => {
  return {
    type: SEND_SHIPPING_TYPE_START_SAGA,
    payload: { stateCode, countryId },
  };
};
export const updatePaymentType = (
  ptype: string | null
): CheckoutActionTypes => {
  if (!ptype) localStorage.removeItem('paymentType');
  else localStorage.setItem('paymentType', ptype);
  return { type: SET_PAYMENT_TYPE, payload: ptype };
};
export const updateIssuer = (issuer: string | null): CheckoutActionTypes => {
  if (!issuer) localStorage.removeItem('issuer');
  else localStorage.setItem('issuer', issuer);
  return { type: SET_ISSUER, payload: issuer };
};

export const getShippingPrice = (countryCode: string, stateCode?: string) => {
  return {
    type: GET_SHIPPING_PRICE_START_SAGA,
    payload: { countryCode, stateCode },
  };
};

export const changePaymentChoices = (
  countryCode: string,
  isPremium?: boolean
) => {
  return {
    type: GET_PAYMENT_CHOICES_START_SAGA,
    payload: { countryCode, isPremium },
  };
};

export const cartSendOrder = (data: ProductOrderType): ISendOrderStartSaga => {
  return { type: SEND_ORDER_START_SAGA, payload: data };
};

export const setShipmentError = (error: [] | null) => {
  return { type: 'SET_SHIPMENT_ERROR', payload: error };
};
