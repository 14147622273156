import React, { Fragment, useState, useEffect } from 'react';
import { RouteProps, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setUserAction } from 'store/actions/user-actions';
import { User } from 'oidc-client';
import { UserType } from 'lib/types';
import userManager from 'util/userManager';
import Layout from 'hoc/Layout';
import ErrorBoundary from 'hoc/ErrorBoundary';
import requestInterceptor from 'util/AxiosHeaders';
import { useTranslation } from 'react-i18next';
import { GTMPageView } from 'util/gtm';

interface IPrivateRouteProps extends RouteProps {
  component: any;
  routeProps: any;
}

const PrivateRoute: React.FC<IPrivateRouteProps> = ({
  component,
  routeProps,
}) => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const Component = component;
  const [user, setUser] = useState<User | null>(null);
  const [isLoadingUser, setIsLoadingUser] = useState(true);
  const location = useLocation();
  const lang = i18n.language.toLowerCase();

  useEffect(() => {
    userManager
      .getUser()
      .then((user) => {
        setUser(user || null);
        setIsLoadingUser(false);
        dispatch(setUserAction(user as unknown as UserType));
        GTMPageView('Checkout', user, location.pathname);
      })
      .catch((err) => {
        // TODO Add Error Message to Redux Store
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (user) requestInterceptor(user, lang);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const renderComponent = () => {
    if (isLoadingUser) return null;
    if (user) {
      return (
        <ErrorBoundary>
          <Layout>
            <Component {...routeProps} />
          </Layout>
        </ErrorBoundary>
      );
    }

    localStorage.setItem('oidc_redirect', window.location.pathname);
    userManager.signinRedirect();
  };

  return <Fragment>{renderComponent()}</Fragment>;
};

export default PrivateRoute;
