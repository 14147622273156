import React from 'react';
import { Container } from 'components/Common/Layout';
import { Button } from 'components/Common/Button';
import styled from 'styled-components/macro';

import { Paragraph } from 'components/Common/Text';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store/reducers/rootReducer';
import { preparePremiumOrder } from 'util/sendOrder';
import { message } from 'antd';
import { sendPremiumOrder } from 'store/actions/premium-plan-actions';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5em;

  button {
    width: 200px;
    margin-top: 2em;
  }
`;

const Heading = styled.h1`
  font-size: 4em;
  line-height: 1.2em;
  font-weight: 700;
  text-align: left;
  margin-bottom: 1em;

  @media (max-width: 768px) {
    text-align: center;
    font-size: 3em;
  }
`;

export const ConfirmDowngradePlan: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { plan } = useSelector((state: AppState) => state.premiumPlan);

  const { userAccount, invoiceInfo } = useSelector(
    (state: AppState) => state.user
  );

  const downgradePremium = async () => {
    const test = 'test';
    const order = preparePremiumOrder({
      invoice: {
        ...invoiceInfo,
        city: test,
        zipCode: 1,
        state: test,
        country: test,
        countryName: test,
        street: test,
        lastName: test,
        firstName: test,
        houseNumber: test,
      },
      issuer: '',
      paymentType: 'test',
      paymentSubscriptionType: plan.type.match(/annual/i) ? 1 : 0,
      premiumModalId: plan.id,
    });

    dispatch(sendPremiumOrder(order));
    message.success({
      content: 'Success',
      style: { marginTop: '75px' },
    });
  };

  return (
    <Container>
      <Wrapper>
        <Heading>{t('downgradeSubscription')}</Heading>
        <Paragraph bold>
          <Trans
            i18nKey={'downgradeSubscriptionText'}
            values={{
              renewDate: userAccount.validUntil,
              newPlan: plan.typeOfPlan.value,
              currentPlan: userAccount?.premiumModelType,
            }}
            components={{ bold: <strong /> }}
          />
        </Paragraph>
        <Button filled size="medium" onClick={downgradePremium}>
          {t('confirm')}
        </Button>
      </Wrapper>
    </Container>
  );
};
