import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { AppState } from 'store/reducers/rootReducer';
import { identify } from './';

export const CustomerIO: React.FC = () => {
  const {
    userAccount: { id },
  } = useSelector(({ user }: AppState) => user);

  useEffect(() => {
    if (!!id) {
      identify(id);
    }
  }, [id]);

  return null;
};
