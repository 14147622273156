import React, { Component } from "react";
import { Translation } from 'react-i18next';

type MyState = {
	hasError: boolean;
};

class ErrorBoundary extends Component<{}, MyState> {
	state: MyState = {
		hasError: false,
	};

	static getDerivedStateFromError(error: any) {
		return {
			hasError: true,
		};
	}

	render() {
		if (this.state.hasError) {
			return (
				<div className="error-boundary">
					<div>
						<h2>Oops !</h2>
						<Translation>
							{(t) => <h3>{t('error_went_wrong')}</h3>}
						</Translation>
						<Translation>
							{(t) => <p>{t('please_go_back')}</p>}
						</Translation>
					</div>
				</div>
			);
		}
		return this.props.children;
	}
}

export default ErrorBoundary;
