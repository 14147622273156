const languages = ['en', 'nl', 'es'];

const routeLangs = 'en|nl|es';

const languageSelect = {
  EN: 'English',
  NL: 'Nederlands',
  ES: 'Español',
};

const languageRegions = {
  en: 'en-US',
  nl: 'nl-NL',
  es: 'es-ES',
};

export { languages, routeLangs, languageSelect, languageRegions };
