import { UserManager, UserManagerSettings } from "oidc-client";

const baseUrl = `${window.location.protocol}//${window.location.hostname}${
	window.location.port ? `:${window.location.port}` : ""
}`;
const authUrl = process.env.REACT_APP_AUTH_URL;
const clientId = process.env.REACT_APP_CLIENT_ID;

const userManagerConfig: UserManagerSettings = {
	authority: authUrl,
	client_id: clientId,
	redirect_uri: `${baseUrl}/signin-oidc`,
	post_logout_redirect_uri: process.env.REACT_APP_WEBSITE_URL,
	silent_redirect_uri: `${baseUrl}/signin-oidc-silent`,
	scope: "openid profile email td-api",
	response_type: "code",
	loadUserInfo: false,
	checkSessionInterval: 60,

	metadata: {
		issuer: authUrl,
		jwks_uri: authUrl + "/.well-known/openid-configuration/jwks",
		authorization_endpoint: authUrl + "/connect/authorize",
		token_endpoint: authUrl + "/connect/token",
		userinfo_endpoint: authUrl + "/connect/userinfo",
		end_session_endpoint: authUrl + "/connect/endsession",
		check_session_iframe: authUrl + "/connect/checksession",
		revocation_endpoint: authUrl + "/connect/revocation",
		introspection_endpoint: authUrl + "/connect/introspect",
	},
};

export default new UserManager(userManagerConfig);
