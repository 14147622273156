import styled from "styled-components/macro";

export const Container = styled.div`
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;

	@media (min-width: 768px) {
		width: 750px;
	}

	@media (min-width: 992px) {
		width: 970px;
	}

	@media (min-width: 1200px) {
		width: 1170px;
	}
`;

export const Divider = styled.hr`
	margin: ${({ spacing }) => (spacing ? `${spacing}em 0` : "2em 0")};
	background: #ededed;
	border: none;
	height: ${({ height }) => (height ? height : 1)}px;
`;
