import {
  SET_USER,
  UPDATE_DELIVERY_INFO,
  UPDATE_INVOICE_INFO,
  GET_COUNTRIES_START,
  GET_COUNTRIES_SUCCESS,
  GET_COUNTRIES_ERROR,
  SET_WITH_INVOICE_ADDRESS,
  UserActionTypes,
  GET_ACCOUNT_DATA_START,
  GET_ACCOUNT_DATA_SUCCESS,
  GET_ACCOUNT_DATA_ERROR,
} from 'store/actions/user-actions/user-action-types';
import { UserType, AddressType, CountryType, AccountProps } from 'lib/types';

const defaultAddress = {
  firstName: '',
  lastName: '',
  company: '',
  street: '',
  houseNumber: '',
  city: '',
  zipCode: null,
  country: '',
  countryName: '',
};

const deliveryInfoStored = JSON.parse(
  localStorage.getItem('deliveryInfo') || '{}'
);
const deliveryInfo = { ...defaultAddress, ...deliveryInfoStored };

const invoiceInfoStored = JSON.parse(
  localStorage.getItem('invoiceInfo') || '{}'
);
const invoiceInfo = { ...defaultAddress, ...invoiceInfoStored };

const withInvoice = localStorage.getItem('withInvoice');

interface IUser {
  user: UserType | {};
  userAccount: AccountProps;
  deliveryInfo: AddressType;
  invoiceInfo: AddressType;
  isAccountLoading: boolean;
  countries: CountryType[];
  isCountriesLoading: boolean;
  withDeliveryAddress: boolean;
}

const initialState: IUser = {
  user: {},
  userAccount: {
    registrationSource: undefined,
    id: '',
    dateFormat: '',
    email: '',
    language: 'EN',
    receiveNewsletter: false,
    acceptedTermsAndConditionsVersion: 0,
    acceptedUserTermsAndConditionsVersion: 0,
  },
  isAccountLoading: false,
  deliveryInfo,
  invoiceInfo,
  countries: [],
  isCountriesLoading: false,
  withDeliveryAddress: !!withInvoice,
};

const UserReducer = (
  state: IUser = initialState,
  action: UserActionTypes
): IUser => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: { ...action.payload },
      };
    case UPDATE_DELIVERY_INFO:
      return {
        ...state,
        deliveryInfo: { ...action.payload },
      };
    case UPDATE_INVOICE_INFO:
      return {
        ...state,
        invoiceInfo: { ...action.payload },
      };
    case SET_WITH_INVOICE_ADDRESS:
      localStorage.setItem('withInvoice', state.withDeliveryAddress ? '' : '1');
      return {
        ...state,
        withDeliveryAddress: !state.withDeliveryAddress,
      };
    case GET_COUNTRIES_START:
      return {
        ...state,
        isCountriesLoading: true,
      };
    case GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        countries: action.payload,
      };
    case GET_COUNTRIES_ERROR:
      return {
        ...state,
        isCountriesLoading: false,
      };
    case GET_ACCOUNT_DATA_START:
      return {
        ...state,
        isAccountLoading: true,
      };
    case GET_ACCOUNT_DATA_SUCCESS:
      return {
        ...state,
        isAccountLoading: false,
        userAccount: action.payload,
      };
    case GET_ACCOUNT_DATA_ERROR: {
      return {
        ...state,
        isAccountLoading: false,
      };
    }
    default:
      return state;
  }
};

export default UserReducer;
