import { AddressType, ProductOrderType, PremiumOrderType } from 'lib/types';
import { convertAddressTypeToMollieeAddress } from './helpFunctions';

interface IPrepareDiaryOrder {
  invoice: AddressType;
  delivery: AddressType;
  issuer: string;
  paymentType: string;
  withDeliveryAddress: boolean;
}

interface IPreparePremiumOrder {
  invoice: AddressType;
  issuer: string;
  paymentType: string;
  paymentSubscriptionType: number;
  premiumModalId: string;
  voucherId?: string;
}

export const prepareProductOrderType = ({
  invoice,
  delivery,
  issuer,
  paymentType,
  withDeliveryAddress,
}: IPrepareDiaryOrder): ProductOrderType => {
  const invoiceAddress = convertAddressTypeToMollieeAddress(invoice);
  const shippingAddress = withDeliveryAddress
    ? convertAddressTypeToMollieeAddress(delivery)
    : invoiceAddress;

  return {
    invoiceAddress,
    shippingAddress,
    paymentMethod: { methodId: paymentType!, issuer: issuer || '' },
    useDifferentDeliveryAddress: withDeliveryAddress,
  };
};

export const preparePremiumOrder = ({
  invoice,
  paymentType,
  issuer,
  paymentSubscriptionType,
  premiumModalId,
  voucherId,
}: IPreparePremiumOrder): PremiumOrderType => {
  const invoiceAddress = convertAddressTypeToMollieeAddress(invoice);

  return {
    invoiceAddress,
    paymentMethod: { methodId: paymentType!, issuer: issuer || '' },
    premiumModalId: premiumModalId,
    paymentSubscriptionType: paymentSubscriptionType,
    voucherId,
  };
};
