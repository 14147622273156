import { useEffect } from 'react';
import Oidc from 'oidc-client';
import requestInterceptor, { getUserData } from 'util/AxiosHeaders';
import { History } from 'history';
import { useTranslation } from 'react-i18next';
import { AccountProps } from 'lib/types';
import { useDispatch } from 'react-redux';
import { GET_ACCOUNT_DATA_SUCCESS } from 'store/actions/user-actions/user-action-types';

const replaceHistory = async (history: History, account: AccountProps) => {
  try {
    let redirectPath = localStorage.getItem('oidc_redirect') || '/en/diary';
    let url = redirectPath.split('/');
    let redirectLang = url[1];

    if (redirectLang !== account.language.toLowerCase()) {
      url[1] = account.language.toLowerCase();
      redirectPath = url.join('/');
    }

    history.replace(redirectPath);
    localStorage.removeItem('oidc_redirect');
  } catch (e) {
    console.log('<< ACCOUNT DATA REQUEST FAILED >>');
    return localStorage.getItem('i18nextLng') || 'en';
  }
};

const SignInOidc = (props: { history: History }) => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    new Oidc.UserManager({ response_mode: 'query' })
      .signinRedirectCallback()
      .then(async (user) => {
        const accountData = await getUserData(user);
        const { language } = accountData;
        replaceHistory(props.history, accountData);
        requestInterceptor(user, language.toLowerCase());
        i18n.changeLanguage(language.toLowerCase());
        dispatch({
          type: GET_ACCOUNT_DATA_SUCCESS,
          payload: accountData,
        });
      })
      .catch((e) => {
        console.error(e);
        props.history.replace(
          localStorage.getItem('oidc_redirect') || 'en/diary'
        );
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n, props.history]);

  return null;
};

export default SignInOidc;
