import React, { Fragment, useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Container } from 'components/Common/Layout';
import { Button } from 'components/Common/Button';
import { Tooltip } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import userManager from 'util/userManager';
import { Helmet } from 'react-helmet';
import { appName } from 'hoc/Layout';
import { hotjar } from 'react-hotjar';
import { CustomerIO, cioScript } from 'hoc/CustomerIO';

const {
  REACT_APP_NAME,
  PUBLIC_URL,
  REACT_APP_FILE_PREFIX,
  REACT_APP_DASHBOARD_URL,
  REACT_APP_WEBSITE_URL,
  NODE_ENV,
} = process.env;

const Hotjar = {
  'Travel Diaries': '2584872',
  'Baby Diaries': '2586620',
};

// Styled Components

const NavOverlay = styled.div`
  display: ${({ isActive }) => (isActive ? 'block' : 'none')};
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 10;
`;

const Nav = styled.nav`
  position: relative;
  background: #ffffff;
  z-index: 15;
  border-bottom: 0.1em solid #ededed;
  padding: 5px;

  @media (max-width: 768px) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 25;
    padding: 1em 0;
  }
`;

const NavWrap = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
`;

const LogoImage = styled.img`
  width: 110px;
  height: 65px;
  left: 7em;
  top: 1.5em;

  @media (max-width: 1200px) {
    left: 4em;
  }

  @media (max-width: 768px) {
    left: 0;
    width: 95px;
    height: 50px;
  }

  ${({ isMobile }) =>
    isMobile && {
      display: 'none',
    }}
`;

const Hamburger = styled.div`
  position: absolute;
  right: 2em;
  top: 2.8em;
  width: 2.2em;
  height: 2em;
  cursor: pointer;
  display: block;
  z-index: 25;

  @media (min-width: 769px) {
    display: none;
  }
`;

const HamburgerSpan = styled.span`
  display: block;
  position: absolute;
  height: 0.2em;
  width: 100%;
  background: grey;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
`;

const SpanOne = styled(HamburgerSpan)`
  ${({ isToggled }) =>
    isToggled ? { top: '0.5em', transform: 'rotate(135deg)' } : { top: 0 }}
`;

const SpanTwo = styled(HamburgerSpan)`
  ${({ isToggled }) =>
    isToggled ? { opacity: 0, left: '-0.6em' } : { top: '0.6em' }}
`;

const SpanThree = styled(HamburgerSpan)`
  ${({ isToggled }) =>
    isToggled
      ? { top: '0.5em', transform: 'rotate(-135deg)' }
      : { top: '1.2em' }}
`;

const NavItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    margin-right: 1em;
  }

  a {
    margin-right: 1em;
    &:last-of-type {
      margin-right: 1em;
    }
  }

  @media (max-width: 768px) {
    &:last-of-type {
      display: none;
    }
  }
`;

const NavPublicLink = styled.a`
  ${({ isDesktop, theme }) =>
    isDesktop && {
      display: 'inline-block',
      fontSize: '1.5em',
      fontWeight: 700,
      padding: '1.7em 1em',
      color: theme.textColor,
      transition: '0.15s all ease-in-out',
    }}

  ${({ isMobile, theme }) =>
    isMobile && {
      color: theme.textColor,
      textAlign: 'left !important',
      borderBottom: '1px solid #ededed',
      fontSize: '1.45em',
      padding: '1.7em 1em',
      fontWeight: 700,
      marginBottom: '0!important',
    }}

	@media (max-width: 768px) {
    ${({ isDesktop }) =>
      isDesktop && {
        display: 'none',
      }}

    &:last-of-type {
      border-bottom: 0;
    }
  }

  &:hover {
    background: #f7f7f7;
    color: ${({ theme }) => theme.textColor};
  }
`;

const NavMobile = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  width: 26em;
  background: #ffffff;
  border-right: 1px solid #ededed;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  z-index: 20;

  ${({ isToggled }) =>
    isToggled
      ? { transform: 'translateX(0)' }
      : { transform: 'translateX(-26em)' }}
`;

const NavMobileLogo = styled.div`
  text-align: left;
  padding: 1em 1.5em;
  border-bottom: 1px solid #ededed;
  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    display: block;
    width: 45px;
    height: 45px;
  }
`;

const NavMobileLinks = styled.div`
  padding: 3em 2em;
  border-bottom: 1px solid #ededed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${({ noPadding }) =>
    noPadding && {
      padding: '0',
    }}

  button,
	a {
    display: block;
    text-align: center;
    width: 100%;
    margin-bottom: 1.75em;
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  a {
    margin-bottom: 1.3em;
  }
`;

const NavSettings = styled.span`
  .anticon {
    font-size: 1.85em;
    cursor: pointer;
    color: ${({ theme }) => theme.primaryColor};

    @media (max-width: 768px) {
      font-size: 2.2em;
    }
  }
`;

// Types

type HeaderTypes = {
  history: any;
  match: any;
};

const headerLinks = {
  'Travel Diaries': {
    en: [
      { infoAndPricing: 'info-and-pricing' },
      { travelInspiration: 'travel-inspiration' },
    ],
    nl: [
      { infoAndPricing: 'info-en-prijzen' },
      { travelInspiration: 'reisinspiratie' },
    ],
    es: [
      { infoAndPricing: 'info-and-pricing' },
      { travelInspiration: 'travel-inspiration' },
    ],
  },
  'Baby Diaries': {
    en: [
      { infoAndPricing: 'info-and-pricing' },
      { travelInspiration: 'travel-inspiration' },
    ],
    nl: [
      { infoAndPricing: 'info-en-prijzen' },
      { travelInspiration: 'reisinspiratie' },
    ],
    es: [
      { infoAndPricing: 'info-and-pricing' },
      { travelInspiration: 'travel-inspiration' },
    ],
  },
};

// Links to Public Website

const Header: React.FC<HeaderTypes> = ({ history, match: { url } }) => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language.toLowerCase();

  const [isToggled, setIsToggled] = useState(false);

  const toggleNavbar = () => {
    setIsToggled(!isToggled);
  };

  const redirectDashboard = (endpoint: string) => {
    window.location.href = `${REACT_APP_DASHBOARD_URL}/${lang}/${endpoint}`;
    setIsToggled(false);
  };

  const publicLinks = headerLinks[appName];

  const renderPublicLinks = (isMobile) =>
    publicLinks[lang].map((link) => {
      const [key, value] = Object.entries(link)[0];
      return (
        <NavPublicLink
          isMobile={isMobile}
          isDesktop={!isMobile}
          href={`${REACT_APP_WEBSITE_URL}/${lang}/${value}`}
          target='_blank'
          key={key}
        >
          {t(key)}
        </NavPublicLink>
      );
    });

  useEffect(() => {
    if (NODE_ENV !== 'development')
      hotjar.initialize(Hotjar[REACT_APP_NAME!], 6);
  }, []);

  const logout = () => {
    userManager.signoutRedirect({ extraQueryParams: { lang } });
  };

  return (
    <Fragment>
      <Helmet>
        <meta charSet='utf-8' />
        <link
          rel='icon'
          href={`${PUBLIC_URL}/${REACT_APP_FILE_PREFIX}/favicon.ico`}
        />
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <meta name='description' content={`${REACT_APP_NAME} checkout.`} />
        <meta name='theme-color' content='#000000' />
        <link rel='manifest' href={`${PUBLIC_URL}/manifest.json`} />
        <link
          href='https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;600;700&display=swap'
          rel='stylesheet'
        />
        <link rel='apple-touch-icon' href={`${PUBLIC_URL}/logo192.png`} />
        <title>Checkout | {REACT_APP_NAME}</title>
        <script async type='text/javascript'>
          {cioScript}
        </script>
      </Helmet>
      <NavOverlay isActive={isToggled} onClick={toggleNavbar} />
      <CustomerIO />
      <Nav>
        <NavMobile isToggled={isToggled}>
          <NavMobileLogo>
            <NavPublicLink href={`${REACT_APP_WEBSITE_URL}/${lang}`}>
              <LogoImage
                src={require(`../../assets/images/${REACT_APP_FILE_PREFIX}-logo.svg`)}
                isMobile
                alt={`${REACT_APP_NAME} - Main Logo`}
              />
            </NavPublicLink>
            <NavSettings>
              <SettingOutlined onClick={() => redirectDashboard('account')} />
            </NavSettings>
          </NavMobileLogo>
          <NavMobileLinks>
            <Button
              size='medium'
              filled
              onClick={() => redirectDashboard('dashboard')}
            >
              Dashboard
            </Button>
            <Button size='medium' outlined onClick={logout}>
              {t('logout')}
            </Button>
          </NavMobileLinks>
          {!url.split('/').includes('checkout') && (
            <NavMobileLinks noPadding>{renderPublicLinks(true)}</NavMobileLinks>
          )}
        </NavMobile>
        <Hamburger onClick={toggleNavbar}>
          <HamburgerSpan as={SpanOne} isToggled={isToggled}></HamburgerSpan>
          <HamburgerSpan as={SpanTwo} isToggled={isToggled}></HamburgerSpan>
          <HamburgerSpan as={SpanThree} isToggled={isToggled}></HamburgerSpan>
        </Hamburger>
        <Container>
          <NavWrap>
            <NavItem>
              <NavPublicLink href={`${REACT_APP_WEBSITE_URL}/${lang}`}>
                <LogoImage
                  src={require(`../../assets/images/${REACT_APP_FILE_PREFIX}-logo.svg`)}
                  alt={`${REACT_APP_NAME} - Main Logo`}
                />
              </NavPublicLink>
              {!url.split('/').includes('checkout') && (
                <Fragment>{renderPublicLinks(false)}</Fragment>
              )}
            </NavItem>
            <NavItem>
              <Button size='medium' outlined onClick={logout}>
                {t('logout')}
              </Button>
              <Button
                size='medium'
                filled
                onClick={() => redirectDashboard('dashboard')}
              >
                Dashboard
              </Button>
              <NavSettings>
                <Tooltip title={t('accountSettings')}>
                  <SettingOutlined
                    onClick={() => redirectDashboard('account')}
                  />
                </Tooltip>
              </NavSettings>
            </NavItem>
          </NavWrap>
        </Container>
      </Nav>
    </Fragment>
  );
};

export default withRouter(Header);
