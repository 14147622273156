export type UserType = {
  access_token: string;
  expires_at: number;
  id_token: string;
  profile: {
    amr: [string];
    auth_time: number;
    email: string;
    idp: string;
    name: string;
    preferred_username: string;
    role: string;
    s_hash: string;
    sid: string;
    sub: string;
  };
  refresh_token?: string | undefined;
  scope: string;
  session_state: string;
  state?: any;
  token_type: string;
};

export type AddressType = {
  firstName: string;
  lastName: string;
  company?: string;
  street: string;
  houseNumber: string;
  city: string;
  zipCode: number;
  country: string;
  countryName: string;
  hasState: boolean;
  state: string;
  phoneNumber?: string;
};

export type State = {
  name: string;
  stateCode: string;
};
export type CountryType = {
  id: string;
  code: string;
  name: string;
  isEUCountry: boolean;
  showStateList: boolean;
  stateList: State[];
};

export type DiaryProduct = {
  id: string;
  name: string;
  properties: any;
  imageUrl: string;
  quantity: number;
  maxQuantity: number;
  productType: string;
  price: {
    currencyCode: string;
    price: number;
  };
};

export type CartReceiptType = {
  shipmentCost: {
    currencyCode: string;
    price: number;
  } | null;
  totalDiscount: {
    currencyCode: string;
    price: number;
  };
  totalPrice: {
    currencyCode: string;
    price: number;
  };
  discounts: [
    {
      name: string;
      amount: {
        currencyCode: string;
        price: number;
      };
      type: DiscountType;
      voucherUsageType: VoucherUsageType;
    }
  ];
};

export type PaymentChoice = {
  id: string;
  name: string;
  image: string;
  issuers:
    | {
        id: string;
        name: string;
        image: string;
      }[]
    | null;
};

export type MollieAddressType = {
  organizationName: string;
  streetAndNumber: string;
  streetAdditional: string;
  city: string;
  region: string;
  postalCode: string;
  country: string;
  title: string;
  givenName: string;
  familyName: string;
  stateCode: string;
  phoneNumber?: string;
};

export type ProductOrderType = {
  invoiceAddress: MollieAddressType;
  shippingAddress: MollieAddressType;
  useDifferentDeliveryAddress: boolean;
  paymentMethod: {
    methodId: string;
    issuer: string;
  };
};

export type IconType = {
  color?: string;
  width: number;
  height: number;
};

export enum DiscountType {
  DiscountVoucher = 1,
  GiftVoucher = 2,
  VolumeDiscount = 3,
}

export enum VoucherUsageType {
  canDelete = 0,
  canNotDelete = 1,
}

export type AccountProps = {
  id: string;
  acceptedTermsAndConditionsVersion?: number;
  acceptedUserTermsAndConditionsVersion?: number;
  dateFormat: string;
  email?: string;
  language: string;
  receiveNewsletter: boolean;
  isValid?: boolean;
  premiumModelType?: 'Free' | 'Basic' | 'Online' | 'Print';
  registrationSource: undefined | 'Web' | 'Android' | 'Apple';
  downGradeRequestDetail?: {
    canceledSubscriptionId: string;
    createdDate: string;
    requestedPremiumModalId: string;
    subscriptionId: string;
    requestedPremiumModelType: string;
  };
  renewDate?: string | null;
  validUntil?: string | null;
};

export enum PremiumCreationType {
  DOWN = 'Down',
  UP = 'Up',
  SAME = 'Same',
}

export type PremiumPlanType = {
  discount: { label: string; value: string };
  discounts: [{ label: string; value: string; isRemovable: boolean }];
  headerValue: string;
  id: string;
  imageUrl: string;
  paymentPlan: { label: string; value: string };
  priceDetailDescription: { label: string; value: string };
  totalAmount: { label: string; value: number };
  totalDescription: { label: string; value: string };
  type: string;
  typeOfPlan: { label: string; value: string };
  voucherResponse: { status: string; voucherId: string };
  premiumCreationType: PremiumCreationType | undefined;
};
export type PremiumReceiptType = {
  totalPrice: {
    currencyCode: string;
    price: number;
  };
};

export type PremiumOrderType = {
  invoiceAddress: MollieAddressType;
  paymentMethod: {
    methodId: string;
    issuer: string;
  };
  premiumModalId: string;
  paymentSubscriptionType: number;
  voucherId?: string;
};
