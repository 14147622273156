import { call, put, takeEvery, all } from 'redux-saga/effects';
import {
  GET_COUNTRIES_START_SAGA,
  GET_COUNTRIES_START,
  GET_COUNTRIES_SUCCESS,
  GET_COUNTRIES_ERROR,
  GET_ACCOUNT_DATA_START,
  GET_ACCOUNT_DATA_SUCCESS,
  GET_ACCOUNT_DATA_ERROR,
  GET_ACCOUNT_DATA_START_SAGA,
} from 'store/actions/user-actions/user-action-types';
import axios from 'axios';
// Sagas
export function* getCountries() {
  try {
    yield put({ type: GET_COUNTRIES_START });

    const {
      data: { data },
    } = yield call(
      axios.get,
      `${process.env.REACT_APP_SERVICES_URL}/shipment/v1/countries`
    );

    yield put({
      type: GET_COUNTRIES_SUCCESS,
      payload: data,
    });
  } catch (err) {
    console.log('ERROR GET COUNTRIES >>>', err);
    yield put({ type: GET_COUNTRIES_ERROR });
  }
}

export function* getAccountData() {
  try {
    yield put({ type: GET_ACCOUNT_DATA_START });
    const {
      data: { data },
    } = yield call(axios.get, `v1/me`);
    yield put({ type: GET_ACCOUNT_DATA_SUCCESS, payload: data });
  } catch (err) {
    console.log('GET ACCOUNT DATA ERROR >>', err.response);
    yield put({ type: GET_ACCOUNT_DATA_ERROR });
  }
}

// Watchers
export function* watchGetCountries() {
  yield takeEvery(GET_COUNTRIES_START_SAGA, getCountries);
}

export function* watchGetAccountData() {
  yield takeEvery(GET_ACCOUNT_DATA_START_SAGA, getAccountData);
}

// Export
export default function* allUserSagas() {
  yield all([watchGetCountries(), watchGetAccountData()]);
}
