import React, { useState, useEffect, ReactElement } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { validateAddresses, decodeURLParams } from 'util/helpFunctions';
import { cartSendOrder } from 'store/actions/checkout-actions';
import { AppState } from 'store/reducers/rootReducer';
import { useTranslation } from 'react-i18next';
import { H2 } from 'components/Common/Text';
import { Container } from 'components/Common/Layout';
import { notification } from 'antd';
import Pagination from 'components/Pagination';
import Stepper from 'components/Stepper';
import ProductStepOne from 'views/Products/steps/ProductStepOne';
import ProductStepTwo from 'views/Products/steps/ProductStepTwo';
import ProductStepThree from 'views/Products/steps/ProductStepThree';
import styled from 'styled-components/macro';
import { track } from 'hoc/CustomerIO';
import { prepareProductOrderType } from 'util/sendOrder';

const StepViewWrap = styled.div`
  padding: 6em 0 6.5em;
  width: 100%;
  margin: 0 auto;
  -webkit-animation: 0.45s fadeIn ease-in-out;
  -moz-animation: 0.45s fadeIn ease-in-out;
  animation: 0.45s fadeIn ease-in-out;

  h2 {
    margin-bottom: 2em;
    text-align: center;
  }

  @media (max-width: 768px) {
    padding: 2.5em 0 3em;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

// Types

type ProductStepViewType = {
  history: any;
  match: any;
};

const paginationNames = ['deliveryAddress', 'paymentOptions', 'orderSummary'];

const ProductStepView: React.FC<ProductStepViewType> = ({
  history,
  match,
}): ReactElement => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [current, setCurrent] = useState<number>(1);
  const lang = React.useMemo(() => i18n.language, [i18n.language]);

  const { withDeliveryAddress, deliveryInfo, invoiceInfo, countries } =
    useSelector((state: AppState) => state.user);

  const { paymentType, issuer, isLoading } = useSelector(
    (state: AppState) => state.checkout
  );

  const { diariesInBasket, receipt } = useSelector(
    (state: AppState) => state.cart
  );

  const setCurrentStep = React.useCallback(
    (step: number) => {
      const url = `/${lang}/checkout/${step || 1}`;
      if (current === step) return;
      history.push(url);
    },
    [current, history, lang]
  );

  useEffect(() => {
    const query: { errorMessage?: string } = decodeURLParams(
      history.location.search
    );
    if (query.errorMessage) {
      setCurrentStep(3);
      setTimeout(
        () => {
          const currentUrl = (window.location.origin + match.url).toString();
          window.history.replaceState(null, '', currentUrl);

          track('Product Checkout Failed', {
            error: query.errorMessage || '',
          });

          notification.error({
            message: t('paymentFailed'),
            description: query.errorMessage,
            duration: 0,
            style: {
              top: '80px',
            },
          });
        },

        1000
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (match.params.step) {
      setCurrent(+match.params.step);
    }
  }, [match]);

  const renderSteps = (): ReactElement => {
    switch (current) {
      case 1:
        return <ProductStepOne />;
      case 2:
        return <ProductStepTwo />;
      case 3:
        return <ProductStepThree />;
      default:
        return <ProductStepOne />;
    }
  };

  const sendOrder = () => {
    const order = prepareProductOrderType({
      invoice: invoiceInfo,
      delivery: deliveryInfo,
      issuer: issuer || '',
      paymentType: paymentType || '',
      withDeliveryAddress,
    });
    track('Product Checkout Completed', {
      items: { ...diariesInBasket },
      receipt,
      ...order,
    });
    dispatch(cartSendOrder(order));
  };

  const disableNextBtn = (): boolean => {
    const isValidForm = validateAddresses(
      withDeliveryAddress,
      invoiceInfo,
      deliveryInfo
    );

    return (
      !isValidForm ||
      !countries.length ||
      (current === 2 && !paymentType) ||
      isLoading
    );
  };

  return (
    <Container>
      <StepViewWrap>
        <H2>{t('checkOutTitle')}</H2>
        <Stepper stepTitles={paginationNames} currentStep={current} />
        {renderSteps()}
        {diariesInBasket.length > 0 && (
          <Pagination
            currentView={current}
            clicked={setCurrentStep}
            history={history}
            sendOrder={sendOrder}
            disableNextBtn={disableNextBtn()}
            paginationNames={paginationNames}
            route="cart"
          />
        )}
      </StepViewWrap>
    </Container>
  );
};

export default ProductStepView;
