import styled from 'styled-components/macro';

export const PriceDetailsWrap = styled.div`
  padding: 0 1.5em;
  width: 40%;

  @media (max-width: 992px) {
    width: 100%;
    margin-top: 3em;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const PriceDetailsInnerWrap = styled.div`
  overflow: hidden;
  width: 100%;
  -webkit-border-radius: 1.5em;
  border: 1px solid #ededed;
  -moz-border-radius: 1.5em;
  border-radius: 1.5em;
  -webkit-box-shadow: rgb(227, 227, 227) 1em 1em 2em 0px;
  -moz-box-shadow: rgb(227, 227, 227) 1em 1em 2em 0px;
  box-shadow: rgb(227, 227, 227) 1em 1em 2em 0px;
`;

export const PriceDetailsHeader = styled.div`
  background: ${({ theme }) => theme.primaryColor};
  padding: 2em;
  overflow: hidden;

  h4 {
    margin-bottom: 0;
    font-size: 1.5em;
    font-weight: 600;
    color: ${({ theme }) => theme.whiteColor};
  }
`;

export const PriceDetailsContent = styled.div`
  padding: 1.5em 2em;
  border-right: 1px solid #f6f6f6;
  border-bottom: 1px solid #f6f6f6;
  border-left: 1px solid #f6f6f6;
`;

export const PriceDetailsList = styled.ul`
  list-style-type: none;
  margin-bottom: 0;
`;

export const PriceDetailsListItem = styled.li`
  font-size: 1.6em;
  font-weight: 400;
  padding: 1em 0;
  border-bottom: 1px solid #ededed;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 100%;
  &:last-of-type {
    border-bottom: 0;
  }

  p {
    font-size: 15px;
    font-weight: 300;
    margin-bottom: 0;
    color: #00b200;
  }
`;

export const ListItem = styled.div`
  display: flex;
  align-items: center;
  font-weight: ${({ bold }) => (bold ? '700' : '400')};
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  b {
    padding-left: 0.5em;
    display: inline-block;
    font-weight: 600;
  }

  &:first-of-type {
    padding-right: 2em;
  }

  @media (max-width: 478px) {
    font-size: 0.95em;
  }
`;

export const PriceWrapper = styled.div`
  display: flex;
  align-items: center;
  font-weight: ${({ bold }) => (bold ? '700' : '400')};
  text-overflow: visible;
  white-space: nowrap;
  min-width: max-content;

  @media (max-width: 478px) {
    font-size: 0.95em;
  }
`;

export const VoucherWrap = styled.div`
  margin-top: 3em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  button {
    margin-left: 1em;
  }
`;

export const ErrorMessage = styled.p`
  color: indianred;
  margin-top: 0.85em;
  font-size: 1.4em;
  margin-left: 0.5em;
`;

export const SuccessMessage = styled.p`
  color: #00b200;
  margin-top: 0.85em;
  font-size: 1.4em;
  margin-left: 0.5em;
`;

export const SafePayments = styled.div`
  margin: 4em 0.5em 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  p {
    font-size: 1.5em;
    padding-left: 1em;
    margin-bottom: 0;
  }

  .anticon {
    font-size: 2.5em;
  }
`;

export const DisplayAddresses = styled.div`
  padding-top: 4em;

  h3 {
    font-size: 1.6em;
    &:first-of-type {
      padding-top: 0.5em;
    }
  }
`;

export const AddressListWrapper = styled.div`
  position: relative;

  .anticon {
    position: absolute;
    top: -1.6em;
    right: 0.2em;
    color: ${({ theme }) => theme.primaryColor};
    font-size: 2em;
    cursor: pointer;

    &:hover {
      color: ${({ theme }) => theme.primaryHover};
    }
  }
`;

export const AddressList = styled.ul`
  list-style-type: none;
  margin-bottom: 0;
`;

export const AddressListItem = styled.li`
  font-size: 1.55em;
  padding-bottom: 1em;
  font-weight: 300;
`;

export const DisplayPayments = styled.div`
  margin-top: 4em;
  position: relative;

  h3 {
    font-size: 1.6em;
    padding-top: 0.5em;
  }

  .anticon {
    position: absolute;
    top: 4.2em;
    right: 1.3em;
    color: ${({ theme }) => theme.primaryColor};
    font-size: 2em;
    cursor: pointer;

    &:hover {
      color: ${({ theme }) => theme.primaryHover};
    }
  }
`;

export const RemoveVoucherWrap = styled.div`
  display: flex;
  justify-content: center;
  border: 1px solid #ededed;
  -webkit-border-radius: 0.2em;
  -moz-border-radius: 0.2em;
  border-radius: 0.2em;
  cursor: pointer;
  -webkit-transition: 0.05s all linear;
  -moz-transition: 0.05s all linear;
  transition: 0.05s all linear;
  padding: 0.2em;
  margin-right: 8px;
  min-width: 24px;

  .anticon {
    color: indianred;
    font-size: 1em;
  }

  &:hover {
    background: indianred;
    border-color: indianred;
    .anticon {
      color: white;
    }
  }
`;
