import TagManager from 'react-gtm-module'
export const GTMPageView = (project: string, user, url: string) => {
    if (process.env.NODE_ENV !== "development"){
        if(!user || !user.profile) return;
        TagManager.initialize({
            gtmId: process.env.REACT_APP_GOOGLE_TAG_ID,
            dataLayer: {
                project: project,
                userID: user?.profile.sub,
                event: 'pageview',
                page: url,
                title: `${project} | ${process.env.REACT_APP_NAME}`
            },
        })
        console.log('GA tracking userID:', user?.profile.sub);
    }
};

export const GTMOrderSuccess = ( transactionId: number, revenue: string, currency: string ) => {
    if (process.env.NODE_ENV !== "development"){
        TagManager.initialize({
            gtmId: process.env.REACT_APP_GOOGLE_TAG_ID,
            dataLayer: {
                transactionId,
                revenue,
                currency,
            },
        })
        console.log('GA transaction tracking:', transactionId);
    }
};

