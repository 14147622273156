import React, { useState, ReactElement, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { AppState } from 'store/reducers/rootReducer';
import { deleteVoucher, postVoucher } from 'store/actions/cart-actions';
import { H3, H4, Paragraph } from 'components/Common/Text';
import { Divider } from 'components/Common/Layout';
import { Input, Popconfirm } from 'antd';
import { Button } from 'components/Common/Button';
import {
  CartReceiptType,
  DiaryProduct,
  AddressType,
  DiscountType,
  VoucherUsageType,
} from 'lib/types';
import {
  LoadingOutlined,
  SafetyOutlined,
  EditOutlined,
  QuestionCircleOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import * as S from '../../Common';

// Types
type PriceDetailsTypes = {
  receipt: CartReceiptType;
  diariesInBasket: DiaryProduct[];
  withInvoiceAddress?: boolean;
  deliveryInfo?: AddressType;
  invoiceInfo?: AddressType;
  paymentType?: any;
  type: string;
};

const PriceDetails: React.FC<PriceDetailsTypes> = ({
  receipt: { totalPrice, totalDiscount, discounts, shipmentCost },
  diariesInBasket,
  withInvoiceAddress,
  deliveryInfo,
  invoiceInfo,
  paymentType,
  type,
}): ReactElement => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { voucherIsLoading, voucherError, voucherSuccess } = useSelector(
    (state: AppState) => state.cart
  );

  const [voucherInput, setVoucherInput] = useState<string>('');

  const hasDiscounts = discounts && discounts.length > 0;

  const renderAddress = (info: any) => {
    const {
      firstName,
      lastName,
      company,
      street,
      houseNumber,
      city,
      zipCode,
      countryName,
      phoneNumber,
    } = info;

    return (
      <S.AddressListWrapper>
        <S.AddressList>
          <S.AddressListItem>
            {firstName} {lastName}
          </S.AddressListItem>
          {company && <S.AddressListItem>{company}</S.AddressListItem>}
          {phoneNumber && (
            <S.AddressListItem>
              {t('phone')}: {phoneNumber}
            </S.AddressListItem>
          )}
          <S.AddressListItem>
            {street} {houseNumber}{' '}
          </S.AddressListItem>
          <S.AddressListItem>
            {' '}
            {zipCode} {city}{' '}
          </S.AddressListItem>
          <S.AddressListItem>{countryName}</S.AddressListItem>
        </S.AddressList>
        <EditOutlined
          onClick={() => history.push(`/${i18n.language}/checkout/1`)}
        />
      </S.AddressListWrapper>
    );
  };

  const renderDeliveryAddress = () => {
    if (withInvoiceAddress) {
      return (
        <>
          <H3>{t('deliveryAddress')}</H3>
          {renderAddress(deliveryInfo)}
        </>
      );
    }
  };

  const renderItems = (): JSX.Element[] | null => {
    if (!diariesInBasket?.length) {
      return null;
    }
    return diariesInBasket.map((item) => (
      <S.PriceDetailsListItem key={item.id}>
        <S.ListItem>
          {item.name} <b>x{item.quantity}</b>
        </S.ListItem>
        <S.ListItem>€ {item.price.price.toFixed(2)}</S.ListItem>
      </S.PriceDetailsListItem>
    ));
  };

  const renderDiscounts = (): JSX.Element[] | null => {
    if (!hasDiscounts) {
      return null;
    }

    return discounts.map((discount, index) => {
      return (
        <S.PriceDetailsListItem key={index}>
          <S.ListItem>
            {(discount.type === DiscountType.DiscountVoucher ||
              discount.type === DiscountType.GiftVoucher) &&
              discount.voucherUsageType === VoucherUsageType.canDelete && (
                <S.RemoveVoucherWrap>
                  <Popconfirm
                    title={t('areYouSure')}
                    onConfirm={() => removeVoucherHandler(discount.name)}
                    icon={
                      <QuestionCircleOutlined style={{ color: 'indianred' }} />
                    }
                  >
                    <DeleteOutlined />
                  </Popconfirm>
                </S.RemoveVoucherWrap>
              )}
            <div>
              {discount.type === DiscountType.DiscountVoucher && t('discount')}
              {discount.type === DiscountType.GiftVoucher && t('giftVoucher')}
              {discount.type === DiscountType.VolumeDiscount &&
                t('volumeDiscount')}
              : {discount.name}
            </div>
          </S.ListItem>
          <S.PriceWrapper>
            - €{discount.amount && discount.amount.price.toFixed(2)}
          </S.PriceWrapper>
        </S.PriceDetailsListItem>
      );
    });
  };

  const submitVoucherHandler = () => {
    dispatch(postVoucher(voucherInput, t('voucherSuccess')));
    setVoucherInput('');
  };

  const removeVoucherHandler = (code) =>
    dispatch(deleteVoucher(code, t('voucherRemove')));

  const renderPaymentType = () => {
    switch (paymentType) {
      case 'ideal':
        return 'Ideal';
      case 'creditcard':
        return 'Credit card';
      case 'paypal':
        return 'PayPal';
      case 'bancontact':
        return 'Bancontact';
      default:
        return '';
    }
  };

  return (
    <S.PriceDetailsWrap>
      <S.PriceDetailsInnerWrap>
        <S.PriceDetailsHeader>
          <H4>{t('priceDetails')}</H4>
        </S.PriceDetailsHeader>
        <S.PriceDetailsContent>
          <S.PriceDetailsList>
            {renderItems()}

            {type === 'checkout' && shipmentCost && (
              <S.PriceDetailsListItem key="shippingCost">
                <S.ListItem>{t('shippingCost')}</S.ListItem>
                <S.ListItem>€ {shipmentCost.price.toFixed(2)} </S.ListItem>
              </S.PriceDetailsListItem>
            )}
            {renderDiscounts()}
            <S.PriceDetailsListItem>
              <S.ListItem bold>{t('totalAmount')}</S.ListItem>
              <S.ListItem bold>€ {totalPrice.price.toFixed(2)}</S.ListItem>
            </S.PriceDetailsListItem>
            {hasDiscounts && (
              <S.PriceDetailsListItem>
                <Paragraph>
                  {t('youWillSave', {
                    currency: '€',
                    amount: totalDiscount.price.toFixed(2),
                  })}
                </Paragraph>
              </S.PriceDetailsListItem>
            )}
          </S.PriceDetailsList>
        </S.PriceDetailsContent>
      </S.PriceDetailsInnerWrap>
      <S.SafePayments>
        <SafetyOutlined />
        <Paragraph>{t('safeSecurePayments')}</Paragraph>
      </S.SafePayments>
      <S.VoucherWrap>
        <Input
          type="text"
          placeholder={t('enterVoucherOrCard')}
          className={`${voucherError ? 'hasError' : ''}`}
          onChange={(event) => setVoucherInput(event.target.value)}
          value={voucherInput}
        />
        <Button
          filled
          size="small"
          onClick={submitVoucherHandler}
          disabled={voucherIsLoading || voucherInput.length === 0}
        >
          {voucherIsLoading ? <LoadingOutlined /> : t('apply')}
        </Button>
      </S.VoucherWrap>
      {voucherError && <S.ErrorMessage>{voucherError}</S.ErrorMessage>}
      {voucherSuccess && <S.SuccessMessage>{voucherSuccess}</S.SuccessMessage>}
      {type === 'checkout' && (
        <Fragment>
          <S.DisplayAddresses>
            <S.PriceDetailsInnerWrap>
              <S.PriceDetailsHeader>
                <H4>{t('address')}</H4>
              </S.PriceDetailsHeader>
              <S.PriceDetailsContent>
                <H3>
                  {withInvoiceAddress
                    ? t('invoiceAddress')
                    : t('deliveryAddress')}
                </H3>
                {renderAddress(invoiceInfo)}
                {withInvoiceAddress && <Divider spacing={2} />}
                {renderDeliveryAddress()}
              </S.PriceDetailsContent>
            </S.PriceDetailsInnerWrap>
          </S.DisplayAddresses>

          <S.DisplayPayments>
            <S.PriceDetailsInnerWrap>
              <S.PriceDetailsHeader>
                <H4>{t('payments')}</H4>
              </S.PriceDetailsHeader>
              <S.PriceDetailsContent>
                <H3>{t('paymentMethod')}</H3>
                <Paragraph>{renderPaymentType()}</Paragraph>
              </S.PriceDetailsContent>
            </S.PriceDetailsInnerWrap>
            <EditOutlined
              onClick={() => history.push(`/${i18n.language}/checkout/2`)}
            />
          </S.DisplayPayments>
        </Fragment>
      )}
    </S.PriceDetailsWrap>
  );
};

export default PriceDetails;
