import { CartReceiptType, DiaryProduct, MollieAddressType } from 'lib/types';
import mixpanel from 'mixpanel-browser';
import { repeater } from 'util/helpers';

export const isTravelDiaries = process.env.REACT_APP_NAME === 'Travel Diaries';

const isProduction = process.env.NODE_ENV === 'production';

const MIXPANEL_TOKEN = isProduction
  ? '6d9c6fc76ae0abe2ada8cda05740b999'
  : '6aff3712f598d877291764e49a92e033';

if (isTravelDiaries) {
  mixpanel.init(MIXPANEL_TOKEN, {
    debug: true,
    track_pageview: true,
    persistence: 'localStorage',
  });
}

type TrackingEvents = {
  'Product Checkout Completed': {
    items: DiaryProduct[];
    receipt: CartReceiptType;
    invoiceAddress: MollieAddressType;
    shippingAddress: MollieAddressType;
    paymentMethod: { methodId: string; issuer: string };
    useDifferentDeliveryAddress: boolean;
  };
  'Product Checkout Paid': {
    orderNumber: number;
    orderAmount: string;
    orderCurrency: string;
  };
  'Product Checkout Failed': { error: string };
  'Premium Checkout Completed': {
    invoiceAddress: MollieAddressType;
    paymentMethod: { methodId: string; issuer: string };
    paymentSubscriptionType: number;
    premiumModalId: string;
    voucherId?: string;
  };
  'Premium Checkout Paid': {
    orderNumber: number;
    orderAmount: string;
    orderCurrency: string;
  };
  'Premium Checkout Failed': { error: string };
};

type EventNames = keyof TrackingEvents;

type EventData = {
  [eventName in EventNames]: TrackingEvents[eventName];
};

const CIOTrack = <T extends EventNames>(eventName: T, data: EventData[T]) => {
  const cio = window._cio;

  if (!!cio) {
    cio.track(eventName, data);
    return true;
  } else {
    return false;
  }
};

const CIOIdentify = (id: string) => {
  const cio = window._cio;

  if (!!cio) {
    cio.identify({ id });
    return true;
  } else {
    return false;
  }
};

const track = <T extends EventNames>(eventName: T, data: EventData[T]) => {
  if (!isProduction) return;

  if (!CIOTrack(eventName, data)) {
    repeater(() => CIOTrack(eventName, data), 3, 3);
  }
  if (isTravelDiaries) {
    mixpanel.track(eventName, data);
  }
};

const identify = (id: string) => {
  if (!isProduction) return;

  if (!CIOIdentify(id)) {
    repeater(() => CIOIdentify(id), 3, 3);
  }
  if (isTravelDiaries) {
    mixpanel.identify(id);
  }
};

export { identify, track };
