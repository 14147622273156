import { PremiumOrderType, PremiumPlanType } from 'lib/types';
import {
  GET_PREMIUM_START_SAGA,
  GET_PREMIUM_SUCCESS,
  GET_PREMIUM_ERROR,
  PremiumPlansActionTypes,
  SET_PREMIUM_PLAN,
  REMOVE_PREMIUM_PLAN,
  ISendPremiumStartSagaAction,
  SEND_PREMIUM_ORDER_START_SAGA,
} from './premium-plan-action-types';

export const getPremiumPlan = (
  id: string,
  type: string,
  voucherCode?: string
): PremiumPlansActionTypes => {
  return { type: GET_PREMIUM_START_SAGA, payload: { id, type, voucherCode } };
};

export const setPremiumPlan = (
  plan: PremiumPlanType
): PremiumPlansActionTypes => {
  localStorage.setItem('premiumPlan', JSON.stringify(plan));
  return { type: SET_PREMIUM_PLAN, payload: plan };
};

export const getPremiumPlanSuccess = (
  payload: PremiumPlanType
): PremiumPlansActionTypes => {
  return { type: GET_PREMIUM_SUCCESS, payload };
};

export const getPremiumPlanError = (
  status: 409 | 500
): PremiumPlansActionTypes => {
  return { type: GET_PREMIUM_ERROR, payload: status };
};

export const removePremiumItem = (message: string): PremiumPlansActionTypes => {
  localStorage.removeItem('premiumPlan');

  return { type: REMOVE_PREMIUM_PLAN, payload: message };
};

export const sendPremiumOrder = (
  data: PremiumOrderType
): ISendPremiumStartSagaAction => {
  return { type: SEND_PREMIUM_ORDER_START_SAGA, payload: data };
};
