import React, {
  useState,
  useEffect,
  ReactElement,
  useCallback,
  useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { decodeURLParams, validateAddress } from 'util/helpFunctions';
import { AppState } from 'store/reducers/rootReducer';
import { useTranslation } from 'react-i18next';
import { H2 } from 'components/Common/Text';
import { Container } from 'components/Common/Layout';
import { notification } from 'antd';
import Pagination from 'components/Pagination';
import Stepper from 'components/Stepper';
import styled from 'styled-components/macro';
import PremiumStepOne from './steps/PremiumStepOne';
import PremiumStepTwo from './steps/PremiumStepTwo';
import PremiumStepThree from './steps/PremiumStepThree';
import { sendPremiumOrder } from 'store/actions/premium-plan-actions';
import { track } from 'hoc/CustomerIO';
import { createAddressSchema } from 'util/addressSchema';
import { preparePremiumOrder } from 'util/sendOrder';

const StepViewWrap = styled.div`
  padding: 6em 0 6.5em;
  width: 100%;
  margin: 0 auto;
  -webkit-animation: 0.45s fadeIn ease-in-out;
  -moz-animation: 0.45s fadeIn ease-in-out;
  animation: 0.45s fadeIn ease-in-out;

  h2 {
    margin-bottom: 2em;
    text-align: center;
  }

  @media (max-width: 768px) {
    padding: 2.5em 0 3em;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

// Types

type StepViewType = {
  history: any;
  match: any;
};

const paginationNames = ['invoiceAddress', 'paymentOptions', 'orderSummary'];

const PremiumStepView: React.FC<StepViewType> = ({
  history,
  match,
}): ReactElement => {
  const { t, i18n } = useTranslation();
  const [current, setCurrent] = useState<number>(1);
  const lang = React.useMemo(() => i18n.language, [i18n.language]);
  const { plan, isLoading, isPremiumSending } = useSelector(
    (state: AppState) => state.premiumPlan
  );
  const { invoiceInfo, countries } = useSelector(
    (state: AppState) => state.user
  );
  const { paymentType, issuer } = useSelector(
    (state: AppState) => state.checkout
  );

  const dispatch = useDispatch();

  const setCurrentStep = React.useCallback(
    (step: number) => {
      const url = `/${lang}/premium/${step || 1}`;
      if (current === step) return;
      history.push(url);
    },
    [current, history, lang]
  );

  useEffect(() => {
    const query: { errorMessage?: string } = decodeURLParams(
      history.location.search
    );
    if (query.errorMessage) {
      setCurrentStep(3);
      setTimeout(() => {
        const currentUrl = (window.location.origin + match.url).toString();
        window.history.replaceState(null, '', currentUrl);

        track('Premium Checkout Failed', {
          error: query.errorMessage || '',
        });

        notification.error({
          message: t('paymentFailed'),
          description: query.errorMessage,
          duration: 0,
          style: {
            top: '80px',
          },
        });
      }, 1000);
    }
  }, [history.location.search, match.url, setCurrentStep, t]);

  useEffect(() => {
    if (match.params.step) {
      setCurrent(+match.params.step);
    }
  }, [match]);

  const renderSteps = (): ReactElement => {
    switch (current) {
      case 1:
        return <PremiumStepOne />;
      case 2:
        return <PremiumStepTwo />;
      case 3:
        return <PremiumStepThree />;
      default:
        return <PremiumStepOne />;
    }
  };

  const sendOrder = useCallback(() => {
    const order = preparePremiumOrder({
      invoice: invoiceInfo,
      paymentType: paymentType ?? '',
      issuer: issuer ?? '',
      paymentSubscriptionType: plan.type.match(/annual/i) ? 1 : 0,
      premiumModalId: plan.id,
      ...(plan.voucherResponse.status === 'OK' && {
        voucherId: plan.voucherResponse.voucherId,
      }),
    });
    track('Premium Checkout Completed', order);

    dispatch(sendPremiumOrder(order));
  }, [invoiceInfo, paymentType, issuer, plan, dispatch]);

  const isNextButtonDisabled = useMemo(() => {
    const addressSchema = createAddressSchema(false);
    const isAddressValid = validateAddress(addressSchema, invoiceInfo);
    const hasnoPaymentType = !paymentType && current === 2;

    return (
      hasnoPaymentType ||
      !isAddressValid ||
      !countries.length ||
      isPremiumSending ||
      isLoading
    );
  }, [
    invoiceInfo,
    countries,
    isPremiumSending,
    isLoading,
    current,
    paymentType,
  ]);

  return (
    <Container>
      <StepViewWrap>
        <H2>{t('checkOutTitle')}</H2>
        <Stepper stepTitles={paginationNames} currentStep={current} />
        {renderSteps()}
        {!!plan.id && (
          <Pagination
            currentView={current}
            clicked={setCurrentStep}
            history={history}
            sendOrder={sendOrder}
            disableNextBtn={isNextButtonDisabled}
            paginationNames={paginationNames}
            route={`get-premium/${plan.id}/${plan.type}`}
          />
        )}
      </StepViewWrap>
    </Container>
  );
};

export default PremiumStepView;
