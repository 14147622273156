import { AddressType } from 'lib/types';
import React, { useMemo } from 'react';
import * as S from 'components/Stepper/styles';
import { Input, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store/reducers/rootReducer';
import {
  updateDeliveryInfo,
  updateInvoiceInfo,
} from 'store/actions/user-actions';

interface AddressFormProps {
  type: 'delivery' | 'invoice';
  address: AddressType;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const AddressForm = ({ type, address, onChange }: AddressFormProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { countries, deliveryInfo, invoiceInfo } = useSelector(
    (state: AppState) => state.user
  );

  const country = useMemo(() => {
    if (!countries) return console.error('!!COUNTRIES NOT LOADED!!');
    const selectedCountry =
      type === 'delivery' ? deliveryInfo.country : invoiceInfo.country;
    return countries.find((c) => c.id === selectedCountry);
  }, [countries, invoiceInfo, deliveryInfo, type]);

  const updateCountry = (value: string) => {
    const countryFound = countries.find((c) => c.id === value);
    if (!countryFound) return console.error('!!CHECK COUNTRY EXISTENCE!!');

    const info = type === 'delivery' ? deliveryInfo : invoiceInfo;
    const address = {
      ...info,
      country: value,
      countryName: countryFound.name,
      hasState: countryFound.showStateList,
      state: '',
    };

    if (type === 'delivery') {
      dispatch(updateDeliveryInfo(address));
    } else {
      dispatch(updateInvoiceInfo(address));
    }
  };

  return (
    <>
      <S.InputsWrap>
        <S.InputGroupDouble>
          <Input
            type="text"
            name="firstName"
            placeholder={t('firstName')}
            value={address.firstName}
            onChange={onChange}
          />
          <Input
            type="text"
            name="lastName"
            placeholder={t('lastName')}
            value={address.lastName}
            onChange={onChange}
          />
        </S.InputGroupDouble>
        <S.InputGroupDouble>
          <Input
            type="text"
            name="company"
            placeholder={t('companyName')}
            value={address.company}
            onChange={onChange}
          />
          <Input
            type="text"
            name="phoneNumber"
            placeholder={t('phoneNumber')}
            value={address.phoneNumber}
            onChange={onChange}
            style={{ appearance: 'none' }}
          />
        </S.InputGroupDouble>
        <S.InputGroupDouble>
          <Input
            type="text"
            name="street"
            placeholder={t('street')}
            value={address.street}
            onChange={onChange}
          />
          <Input
            type="text"
            name="houseNumber"
            placeholder={t('houseNumber')}
            value={address.houseNumber}
            onChange={onChange}
          />
        </S.InputGroupDouble>
        <S.InputGroupDouble>
          <Input
            type="text"
            name="zipCode"
            placeholder={t('zipCode')}
            value={address.zipCode}
            onChange={onChange}
          />
          <Input
            type="text"
            name="city"
            placeholder={t('city')}
            value={address.city}
            onChange={onChange}
          />
        </S.InputGroupDouble>
        <S.InputGroupSingle>
          <Select
            showSearch
            optionFilterProp="label"
            options={countries.map((c) => ({ label: c.name, value: c.id }))}
            value={!!address.country ? address.country : undefined}
            placeholder={t('country')}
            onChange={updateCountry}
          />
        </S.InputGroupSingle>

        {country && country.showStateList && country.stateList.length > 0 && (
          <S.InputGroupSingle>
            <Select
              showSearch
              optionFilterProp="label"
              options={country.stateList.map((s) => ({
                label: s.name,
                value: s.stateCode,
              }))}
              placeholder={t('state')}
              value={!!address.state ? address.state : undefined}
              onChange={(value) =>
                onChange({
                  target: { name: 'state', value },
                } as React.ChangeEvent<HTMLInputElement>)
              }
            />
          </S.InputGroupSingle>
        )}
      </S.InputsWrap>
    </>
  );
};
