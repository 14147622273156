import { PaymentChoice, ProductOrderType } from 'lib/types';

export const SET_SHIPPING_ID = 'SET_SHIPPING_ID';
export const SET_PAYMENT_TYPE = 'SET_PAYMENT_TYPE';
export const SET_ISSUER = 'SET_ISSUER';

export const GET_SHIPPING_PRICE_START_SAGA = 'GET_SHIPPING_PRICE_START_SAGA';
export const GET_SHIPPING_PRICE_START = 'GET_SHIPPING_PRICE_START';
export const GET_SHIPPING_PRICE_SUCCESS = 'GET_SHIPPING_PRICE_SUCCESS';
export const GET_SHIPPING_PRICE_ERROR = 'GET_SHIPPING_PRICE_ERROR';

export const SEND_SHIPPING_TYPE_START_SAGA = 'SEND_SHIPPING_TYPE_START_SAGA';
export const SEND_SHIPPING_TYPE_START = 'SEND_SHIPPING_TYPE_START';
export const SEND_SHIPPING_TYPE_SUCCESS = 'SEND_SHIPPING_TYPE_SUCCESS';
export const SEND_SHIPPING_TYPE_ERROR = 'SEND_SHIPPING_TYPE_ERROR';

export const GET_PAYMENT_CHOICES_START_SAGA = 'GET_PAYMENT_CHOICES_START_SAGA';
export const GET_PAYMENT_CHOICES_START = 'GET_PAYMENT_CHOICES_START';
export const GET_PAYMENT_CHOICES_SUCCESS = 'GET_PAYMENT_CHOICES_SUCCESS';
export const GET_PAYMENT_CHOICES_ERROR = 'GET_PAYMENT_CHOICES_ERROR';

export const SEND_ORDER_START_SAGA = 'SED_ORDER_START_SAGA';
export const SEND_ORDER_START = 'SED_ORDER_START';
export const SEND_ORDER_SUCCESS = 'SED_ORDER_SUCCESS';
export const SEND_ORDER_ERROR = 'SED_ORDER_ERROR';

export const SET_SHIPMENT_ERROR = 'SET_SHIPMENT_ERROR';

export interface ISetShipmentErrorAction {
  type: typeof SET_SHIPMENT_ERROR;
  payload: [] | null;
}

export interface ISetShippingTypeAction {
  type: typeof SET_SHIPPING_ID;
  payload: string | null;
}

export interface ISetPaymentTypeAction {
  type: typeof SET_PAYMENT_TYPE;
  payload: string | null;
}

export interface ISetIssuerAction {
  type: typeof SET_ISSUER;
  payload: string | null;
}

export interface IGetShippingPriceStartSagaAction {
  type: typeof GET_SHIPPING_PRICE_START_SAGA;
  payload: { countryCode: string; hasState: boolean; stateCode: string };
}

export interface IGetShippingPriceStartAction {
  type: typeof GET_SHIPPING_PRICE_START;
}

export interface IGetShippingPriceSuccessAction {
  type: typeof GET_SHIPPING_PRICE_SUCCESS;
  payload: {
    shippingPrice: string;
    isSuccess: boolean;
    errorMessages: [];
  };
}

export interface IGetShippingPriceErrorAction {
  type: typeof GET_SHIPPING_PRICE_ERROR;
  payload: string;
}

export interface IGetPaymentChoicesStartSagaAction {
  type: typeof GET_PAYMENT_CHOICES_START_SAGA;
  payload: {
    countryCode: string;
    isPremium?: boolean;
  };
}

export interface IGetPaymentChoicesStartAction {
  type: typeof GET_PAYMENT_CHOICES_START;
}

export interface IGetPaymentChoicesSuccessAction {
  type: typeof GET_PAYMENT_CHOICES_SUCCESS;
  payload: PaymentChoice[];
}

export interface IGetPaymentChoicesErrorAction {
  type: typeof GET_PAYMENT_CHOICES_ERROR;
  payload: string;
}

export interface ISendOrderStartSaga {
  type: typeof SEND_ORDER_START_SAGA;
  payload: ProductOrderType;
}

export interface ISendOrderStart {
  type: typeof SEND_ORDER_START;
}

export interface ISendOrderSuccess {
  type: typeof SEND_ORDER_SUCCESS;
}

export interface ISendOrderError {
  type: typeof SEND_ORDER_ERROR;
  payload: string;
}

export type CheckoutActionTypes =
  | ISetShippingTypeAction
  | ISetPaymentTypeAction
  | ISetIssuerAction
  | IGetShippingPriceStartSagaAction
  | IGetShippingPriceStartAction
  | IGetShippingPriceSuccessAction
  | IGetShippingPriceErrorAction
  | IGetPaymentChoicesStartSagaAction
  | IGetPaymentChoicesStartAction
  | IGetPaymentChoicesSuccessAction
  | IGetPaymentChoicesErrorAction
  | ISendOrderStartSaga
  | ISendOrderStart
  | ISendOrderSuccess
  | ISendOrderError
  | ISetShipmentErrorAction;
