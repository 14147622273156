import { DiaryProduct, CartReceiptType } from 'lib/types';

export const GET_CART_START_SAGA = 'GET_CART_START_SAGA';
export const GET_CART_START = 'GET_CART_START';
export const GET_CART_SUCCESS = 'GET_CART_SUCCESS';
export const GET_CART_ERROR = 'SET_USER_ERROR';

export const SET_CART_QUANTITY_START_SAGA = 'SET_CART_QUANTITY_START_SAGA';
export const SET_CART_QUANTITY_START = 'SET_CART_QUANTITY_START';
export const SET_CART_QUANTITY_SUCCESS = 'SET_CART_QUANTITY_SUCCESS';
export const SET_CART_QUANTITY_ERROR = 'SET_CART_QUANTITY_ERROR';

export const DELETE_CART_ITEM_START_SAGA = 'DELETE_CART_ITEM_START_SAGA';
export const DELETE_CART_ITEM_START = 'DELETE_CART_ITEM_START';
export const DELETE_CART_ITEM_SUCCESS = 'DELETE_CART_ITEM_SUCCESS';
export const DELETE_CART_ITEM_ERROR = 'DELETE_CART_ITEM_ERROR';

export const POST_VOUCHER_START_SAGA = 'POST_VOUCHER_START_SAGA';
export const POST_VOUCHER_START = 'POST_VOUCHER_START';
export const POST_VOUCHER_SUCCESS = 'POST_VOUCHER_SUCCESS';
export const POST_VOUCHER_ERROR = 'POST_VOUCHER_ERROR';

export const REMOVE_VOUCHER_START_SAGA = 'REMOVE_VOUCHER_START_SAGA';
export const REMOVE_VOUCHER_START = 'REMOVE_VOUCHER_START';
export const REMOVE_VOUCHER_SUCCESS = 'REMOVE_VOUCHER_SUCCESS';
export const REMOVE_VOUCHER_ERROR = 'REMOVE_VOUCHER_ERROR';

export const UPDATE_RECEIPT_START_SAGA = 'UPDATE_RECEIPT_START_SAGA';
export const UPDATE_RECEIPT_START = 'UPDATE_RECEIPT_START';
export const UPDATE_RECEIPT_SUCCESS = 'UPDATE_RECEIPT_SUCCESS';
export const UPDATE_RECEIPT_ERROR = 'UPDATE_RECEIPT_ERROR';

export const RESET_CART = 'RESET_CART';

export interface IGetCartStartSagaAction {
  type: typeof GET_CART_START_SAGA;
}

export interface IGetCartStartAction {
  type: typeof GET_CART_START;
  payload: boolean;
}

export interface IGetCartSuccessAction {
  type: typeof GET_CART_SUCCESS;
  payload: {
    diariesInBasket: DiaryProduct[];
    receipt: CartReceiptType;
  };
}

export interface IGetCartErrorAction {
  type: typeof GET_CART_ERROR;
  payload: string;
}

export interface ISetCartQuantityStartSagaAction {
  type: typeof SET_CART_QUANTITY_START_SAGA;
  payload: { itemId: string; quantity: number };
}

export interface ISetCartQuantityStartAction {
  type: typeof SET_CART_QUANTITY_START;
}

export interface ISetCartQuantitySuccessAction {
  type: typeof SET_CART_QUANTITY_SUCCESS;
  payload: {
    diariesInBasket: DiaryProduct[];
    receipt: CartReceiptType;
  };
}

export interface ISetCartQuantityErrorAction {
  type: typeof SET_CART_QUANTITY_ERROR;
  payload: string;
}
export interface IDeleteCartItemStartSagaAction {
  type: typeof DELETE_CART_ITEM_START_SAGA;
  payload: { itemId: string; name: string };
}

export interface IDeleteCartItemStartAction {
  type: typeof DELETE_CART_ITEM_START;
}

export interface IDeleteCartItemSuccessAction {
  type: typeof DELETE_CART_ITEM_SUCCESS;
  payload: {
    diariesInBasket: DiaryProduct[];
    receipt: CartReceiptType;
    name: string;
  };
}

export interface IDeleteCartItemErrorAction {
  type: typeof DELETE_CART_ITEM_ERROR;
  payload: string;
}

export interface IPostVoucherStartSaga {
  type: typeof POST_VOUCHER_START_SAGA;
  payload: {
    code: string;
    successMessage: string;
  };
}

export interface IPostVoucherStartAction {
  type: typeof POST_VOUCHER_START;
}

export interface IPostVoucherSuccessAction {
  type: typeof POST_VOUCHER_SUCCESS;
  payload: {
    diariesInBasket: DiaryProduct[];
    receipt: CartReceiptType;
    successMessage: string;
  };
}

export interface IPostVoucherErrorAction {
  type: typeof REMOVE_VOUCHER_ERROR;
  payload: string;
}

export interface IRemoveVoucherStartSaga {
  type: typeof REMOVE_VOUCHER_START_SAGA;
  payload: {
    code: string;
    successMessage: string;
  };
}

export interface IRemoveVoucherStartAction {
  type: typeof REMOVE_VOUCHER_START;
  payload: {
    code: string;
    successMessage: string;
  };
}

export interface IRemoveVoucherSuccessAction {
  type: typeof REMOVE_VOUCHER_SUCCESS;
  payload: {
    diariesInBasket: DiaryProduct[];
    receipt: CartReceiptType;
    successMessage: string;
  };
}

export interface IRemoveVoucherErrorAction {
  type: typeof POST_VOUCHER_ERROR;
  payload: string;
}

export interface IResetCartAction {
  type: typeof RESET_CART;
}

export interface IUpdateReceiptStartSagaAction {
  type: typeof UPDATE_RECEIPT_START_SAGA;
  payload: { countryId: string; stateCode?: string };
}

export interface IUpdateReceiptStartAction {
  type: typeof UPDATE_RECEIPT_START;
}

export interface IUpdateReceiptSuccessAction {
  type: typeof UPDATE_RECEIPT_SUCCESS;
  payload: CartReceiptType;
}

export interface IUpdateReceiptErrorAction {
  type: typeof UPDATE_RECEIPT_ERROR;
  payload: string;
}

export type CartActionTypes =
  | IGetCartStartSagaAction
  | IGetCartStartAction
  | IGetCartSuccessAction
  | IGetCartErrorAction
  | ISetCartQuantityStartSagaAction
  | ISetCartQuantityStartAction
  | ISetCartQuantitySuccessAction
  | ISetCartQuantityErrorAction
  | IDeleteCartItemStartSagaAction
  | IDeleteCartItemStartAction
  | IDeleteCartItemSuccessAction
  | IDeleteCartItemErrorAction
  | IPostVoucherStartSaga
  | IPostVoucherStartAction
  | IPostVoucherSuccessAction
  | IPostVoucherErrorAction
  | IResetCartAction
  | IRemoveVoucherStartSaga
  | IRemoveVoucherStartAction
  | IRemoveVoucherErrorAction
  | IRemoveVoucherSuccessAction
  | IUpdateReceiptStartSagaAction
  | IUpdateReceiptStartAction
  | IUpdateReceiptSuccessAction
  | IUpdateReceiptErrorAction;
