import { z } from 'zod';

export const createAddressSchema = (hasState: boolean) => {
  return z.object({
    firstName: z.string().min(1, { message: 'First name is required' }),
    lastName: z.string().min(1, { message: 'Last name is required' }),
    company: z.string().optional(),
    street: z.string().min(1, { message: 'Street is required' }),
    houseNumber: z.string().min(1, { message: 'House number is required' }),
    city: z.string().min(1, { message: 'City is required' }),
    zipCode: z.string({ required_error: 'Zip code is required' }),
    country: z.string().min(1, { message: 'Country is required' }),
    countryName: z.string().min(1, { message: 'Country name is required' }),
    state: hasState
      ? z
          .string()
          .min(1, { message: "State is required when 'hasState' is true" })
      : z.string().optional(),
    phoneNumber: z.string().optional(),
  });
};
