import styled from "styled-components/macro";

export const Button = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	-webkit-transition: 0.2s all ease-in-out;
	-moz-transition: 0.2s all ease-in-out;
	transition: 0.2s all ease-in-out;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	cursor: pointer;
	height: 2.7em;

	.anticon {
		display: inline-block;
		font-size: 15px !important;
		margin-right: 10px;
	}

	${({ size }) => {
		switch (size) {
			case "small":
				return {
					fontSize: "14px",
					padding: "0.6em 2em",
					fontWeight: "700",
					lineHeight: "1.2em",
				};
			case "medium":
				return {
					fontSize: "15px",
					padding: "0.6em 2em",
					fontWeight: "700",
					lineHeight: "1.2em",
				};
			case "large":
				return {
					fontSize: "18px",
					padding: "0.6em 2em",
					fontWeight: "700",
					lineHeight: "1.2em",
				};
			default:
				return {
					fontSize: "15px",
					padding: "0.6em 2em",
					fontWeight: "700",
					lineHeight: "1.2em",
				};
		}
	}};

	${({ filled, theme }) =>
		filled && {
			background: theme.primaryColor,
			color: theme.whiteColor,
			border: `2px solid ${theme.primaryColor}`,
		}}

	${({ outlined, theme }) =>
		outlined && {
			background: theme.whiteColor,
			color: theme.primaryColor,
			border: `2px solid ${theme.primaryColor}`,
		}}

	${({ facebook, theme }) =>
		facebook && {
			background: "#3b5998",
			border: `2px solid ${theme.facebook}`,
			color: theme.whiteColor,
		}}

	${({ twitter, theme }) =>
		twitter && {
			background: "#00acee",
			border: `2px solid ${theme.twitter}`,
			color: theme.whiteColor,
		}}	

	&:hover {
		${({ filled, theme }) =>
			filled && {
				background: theme.primaryHover,
				boxShadow: "rgba(33, 33, 33, 0.1) 0px 0px 11px",
				border: `2px solid ${theme.primaryHover}`,
			}}

		${({ outlined, theme }) =>
			outlined && {
				background: theme.primaryWhite,
				boxShadow: "rgba(33, 33, 33, 0.1) 0px 0px 11px",
				color: theme.primaryHover,
				border: `2px solid ${theme.primaryHover}`,
			}}

		${({ facebook, theme }) =>
			facebook && {
				background: "rgba(59, 89, 152, 0.9)",
				boxShadow: "rgba(33, 33, 33, 0.1) 0px 0px 11px",
				border: `2px solid ${theme.facebook}`,
			}}

		${({ twitter, theme }) =>
			twitter && {
				background: "rgba(0, 172, 238, 0.9)",
				boxShadow: "rgba(33, 33, 33, 0.1) 0px 0px 11px",
				border: `2px solid ${theme.twitter}`,
			}}	
			
		${({ disabled, theme }) =>
			disabled && {
				background: theme.disabledColor,
				border: `2px solid ${theme.disabledColor}`,
			}}
	}

	${({ disabled, theme }) =>
		disabled && {
			background: theme.disabledColor,
			border: `2px solid ${theme.disabledColor}`,
			boxShadow: "rgba(33, 33, 33, 0.1) 0px 0px 11px",
			cursor: "not-allowed",
		}}

	&:focus {
		outline: none;
	}

	@media (max-width: 768px) {
		font-size: 1.4em;
	}
`;

export const Anchor = styled.a`
	display: inline-block;
	height: auto;
	color: red;

	${({ type, theme }) =>
		type === "primary" && {
			color: theme.primaryColor,
		}}

	&:hover {
		color: #ffffff;

		${({ type, theme }) =>
			type === "primary" && {
				color: theme.primaryHover,
			}}
	}
`;
