import i18n from 'i18n';
import { PremiumPlanType } from 'lib/types';
import {
  GET_PREMIUM_START,
  GET_PREMIUM_SUCCESS,
  GET_PREMIUM_ERROR,
  SEND_PREMIUM_ORDER_START,
  SEND_PREMIUM_ORDER_SUCCESS,
  SEND_PREMIUM_ORDER_ERROR,
  PremiumPlansActionTypes,
  REMOVE_PREMIUM_PLAN,
} from 'store/actions/premium-plan-actions/premium-plan-action-types';

interface IPremiumPlanCart {
  plan: PremiumPlanType;
  isLoading: boolean;
  isPremiumSending: boolean;
  errorPremium: string;
  errorResponseCode: number | null;
}
const emptyPlan: PremiumPlanType = {
  id: '',
  priceDetailDescription: { label: '', value: '' },
  headerValue: '',
  imageUrl: '',
  type: '',
  discount: { label: '', value: '' },
  discounts: [{ label: '', value: '', isRemovable: false }],
  typeOfPlan: { label: '', value: '' },
  paymentPlan: { label: '', value: '' },
  totalAmount: { label: '', value: NaN },
  totalDescription: { label: '', value: '' },
  voucherResponse: {
    status: '',
    voucherId: '',
  },
  premiumCreationType: undefined,
};
const storedplan = JSON.parse(localStorage.getItem('premiumPlan') || '{}');

const initialPlan = storedplan.id ? storedplan : emptyPlan;

const initialState: IPremiumPlanCart = {
  plan: initialPlan,
  isLoading: false,
  isPremiumSending: false,
  errorPremium: '',
  errorResponseCode: null,
};

const CartReducer = (
  state: IPremiumPlanCart = initialState,
  action: PremiumPlansActionTypes
): IPremiumPlanCart => {
  switch (action.type) {
    case GET_PREMIUM_START:
      return {
        ...state,
        isLoading: true,
      };
    case GET_PREMIUM_SUCCESS:
      return {
        ...state,
        plan: action.payload,
        isLoading: false,
      };
    case GET_PREMIUM_ERROR:
      const responseCode = action.payload;

      if (responseCode === 409) {
        return {
          ...state,
          isLoading: false,
          errorResponseCode: action.payload,
        };
      }
      return {
        ...state,
        isLoading: false,
        errorPremium: i18n.t('basketNotFound'),
        errorResponseCode: action.payload,
      };
    case REMOVE_PREMIUM_PLAN:
      return {
        ...state,
        plan: emptyPlan,
      };
    case SEND_PREMIUM_ORDER_START:
      return {
        ...state,
        isPremiumSending: true,
      };
    case SEND_PREMIUM_ORDER_SUCCESS:
      return {
        ...state,
        isPremiumSending: false,
        errorPremium: '',
      };
    case SEND_PREMIUM_ORDER_ERROR:
      return {
        ...state,
        isPremiumSending: false,
        errorPremium: action.payload,
      };

    default:
      return state;
  }
};

export default CartReducer;
