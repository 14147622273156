import React, { ReactElement } from 'react';
import { DiaryProduct } from 'lib/types';
import { useDispatch } from 'react-redux';
import {
  setQuantityCartItem,
  deleteCartItem,
} from 'store/actions/cart-actions';
import { debounce } from 'lodash';
import ProductCard from 'components/Views/ProductView/Products/ProductCard';
import styled from 'styled-components/macro';
import { Button } from 'components/Common/Button';
import { useTranslation } from 'react-i18next';

// Styled Components

const ProductsWrap = styled.div`
  width: 60%;
  padding: 0 1.5em;
  display: flex;
  flex-direction: column;

  @media (max-width: 992px) {
    width: 100%;
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 5em;
  }
`;

// Types

type ProductsTypes = {
  diariesInBasket: DiaryProduct[];
};

const Products: React.FC<ProductsTypes> = ({
  diariesInBasket,
}): ReactElement => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const deleteItem = (id: string, name: string) =>
    dispatch(deleteCartItem(id, name));

  const setQuantity = (id: string, quantity: number) =>
    dispatch(setQuantityCartItem(id, quantity));

  const handleDebouncedQuantity = debounce(setQuantity, 250);

  const redirectDashboard = () => {
    window.location.href = `${process.env.REACT_APP_DASHBOARD_URL}/${i18n.language}/dashboard`;
  };

  const itemsExist = diariesInBasket && diariesInBasket.length > 0;

  return (
    <ProductsWrap>
      {itemsExist &&
        diariesInBasket.map((item) => {
          return (
            <ProductCard
              {...item}
              key={item.id}
              setQuantity={handleDebouncedQuantity}
              deleteCartItem={deleteItem}
            />
          );
        })}
      {itemsExist && (
        <Button
          filled
          size="medium"
          onClick={redirectDashboard}
          style={{ width: 'max-content' }}
        >
          {t('addMoreDiaries')}
        </Button>
      )}
    </ProductsWrap>
  );
};

export default Products;
