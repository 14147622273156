import axios from 'axios';
import { AccountProps } from 'lib/types';
import { User } from 'oidc-client';
import { languageRegions } from './languages';

const requestInterceptor = (
  user: { access_token: string },
  lang: string = 'en'
) => {
  return axios.interceptors.request.use(
    (config) => {
      const token = user.access_token;

      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }

      config.headers['Content-Type'] = 'application/json';
      config.headers['Accept-Language'] = languageRegions[lang];
      config.headers['x-api-key'] = process.env.REACT_APP_PRODUCT_API_KEY;
      config.baseURL = process.env.REACT_APP_API_URL;

      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );
};

export const getUserData = async (user: User): Promise<AccountProps> => {
  try {
    const userAccount = await axios
      .get(`${process.env.REACT_APP_API_URL}/v1/me`, {
        headers: {
          Authorization: `Bearer ${user.access_token}`,
          'Content-Type': 'application/json',
          'x-api-key': process.env.REACT_APP_PRODUCT_API_KEY,
        },
      })
      .then((res) => res.data && res.data.data);
    return userAccount;
  } catch (error) {
    console.log(error, '<< ACCOUNT DATA REQUEST FAILED >>');
    return {
      id: '',
      dateFormat: '',
      email: '',
      language: 'EN',
      receiveNewsletter: false,
      acceptedTermsAndConditionsVersion: 0,
      acceptedUserTermsAndConditionsVersion: 0,
      isValid: false,
      registrationSource: undefined,
    };
  }
};

export default requestInterceptor;
