import { UserType, AddressType, CountryType, AccountProps } from 'lib/types';

export const SET_USER = 'SET_USER';
export const SET_USER_ERROR = 'SET_USER_ERROR';

export const UPDATE_DELIVERY_INFO = 'UPDATE_DELIVERY_INFO';
export const UPDATE_INVOICE_INFO = 'UPDATE_INVOICE_INFO';
export const SET_WITH_INVOICE_ADDRESS = 'SET_WITH_INVOICE_ADDRESS';

export const GET_COUNTRIES_START_SAGA = 'GET_COUNTRIES_START_SAGA';
export const GET_COUNTRIES_START = 'GET_COUNTRIES_START';
export const GET_COUNTRIES_SUCCESS = 'GET_COUNTRIES_SUCCESS';
export const GET_COUNTRIES_ERROR = 'GET_COUNTRIES_ERROR';

export const GET_ACCOUNT_DATA_START_SAGA = 'GET_ACCOUNT_DATA_START_SAGA';
export const GET_ACCOUNT_DATA_START = 'GET_ACCOUNT_DATA_START';
export const GET_ACCOUNT_DATA_SUCCESS = 'GET_ACCOUNT_DATA_SUCCESS';
export const GET_ACCOUNT_DATA_ERROR = 'GET_ACCOUNT_DATA_ERROR ';

export interface ISetUserAction {
  type: typeof SET_USER;
  payload: UserType;
}

export interface ISetUserErrorAction {
  type: typeof SET_USER_ERROR;
}

export interface IUpdateDeliveryInfoAction {
  type: typeof UPDATE_DELIVERY_INFO;
  payload: AddressType;
}

export interface IUpdateInvoiceInfoAction {
  type: typeof UPDATE_INVOICE_INFO;
  payload: AddressType;
}

export interface ISetWithInvoiceAction {
  type: typeof SET_WITH_INVOICE_ADDRESS;
}

export interface IGetCountriesStartSagaAction {
  type: typeof GET_COUNTRIES_START_SAGA;
}

export interface IGetCountriesStartAction {
  type: typeof GET_COUNTRIES_START;
}

export interface IGetCountriesSuccessAction {
  type: typeof GET_COUNTRIES_SUCCESS;
  payload: CountryType[];
}

export interface IGetCountriesErrorAction {
  type: typeof GET_COUNTRIES_ERROR;
}

export interface IGetAccountDataStartSaga {
  type: typeof GET_ACCOUNT_DATA_START_SAGA;
}

export interface IGetAccountDataStartAction {
  type: typeof GET_ACCOUNT_DATA_START;
}

export interface IGetAccountDataSuccessAction {
  type: typeof GET_ACCOUNT_DATA_SUCCESS;
  payload: AccountProps;
}

export interface IGetAccountDataErrorAction {
  type: typeof GET_ACCOUNT_DATA_ERROR;
}

export type UserActionTypes =
  | ISetUserAction
  | IUpdateDeliveryInfoAction
  | IUpdateInvoiceInfoAction
  | ISetWithInvoiceAction
  | ISetUserErrorAction
  | IGetCountriesStartSagaAction
  | IGetCountriesStartAction
  | IGetCountriesSuccessAction
  | IGetCountriesErrorAction
  | IGetAccountDataStartSaga
  | IGetAccountDataStartAction
  | IGetAccountDataSuccessAction
  | IGetAccountDataErrorAction;
