import { put, takeLatest, call, all } from 'redux-saga/effects';
import axios from 'axios';
import {
  GET_SHIPPING_PRICE_START_SAGA,
  GET_SHIPPING_PRICE_SUCCESS,
  GET_SHIPPING_PRICE_ERROR,
  IGetShippingPriceStartSagaAction,
  GET_PAYMENT_CHOICES_START_SAGA,
  GET_PAYMENT_CHOICES_START,
  GET_PAYMENT_CHOICES_SUCCESS,
  GET_PAYMENT_CHOICES_ERROR,
  SEND_ORDER_START_SAGA,
  SEND_ORDER_START,
  SEND_ORDER_SUCCESS,
  SEND_ORDER_ERROR,
  IGetPaymentChoicesStartSagaAction,
  ISendOrderStartSaga,
  GET_SHIPPING_PRICE_START,
} from 'store/actions/checkout-actions/checkout-action-types';
import i18n from 'i18n';

const redirectToPayment = (extURL: string) => (window.location.href = extURL);

// Sagas
export function* getShippingPrice(action: IGetShippingPriceStartSagaAction) {
  try {
    yield put({ type: GET_SHIPPING_PRICE_START });
    const { countryCode, stateCode } = action.payload;

    const { data } = yield call(
      axios.get,
      `${process.env.REACT_APP_SERVICES_URL}/shipment/v1/shipmentprice/${countryCode}/${stateCode}`
    );

    if (data) {
      const { totalPrice, isSuccess, errorMessages } = data;
      yield put({
        type: GET_SHIPPING_PRICE_SUCCESS,
        payload: { shippingPrice: totalPrice, isSuccess, errorMessages },
      });
    }
  } catch (err) {
    console.log('Shipping choices ERROR >>', err);
    yield put({
      type: GET_SHIPPING_PRICE_ERROR,
      payload: i18n.t('basketNotFound'),
    });
  }
}

export function* getPaymentChoices(action: IGetPaymentChoicesStartSagaAction) {
  try {
    yield put({ type: GET_PAYMENT_CHOICES_START });

    const {
      data: { data },
    } = yield call(
      axios.get,
      `${process.env.REACT_APP_SERVICES_URL}/orders/v1/payment-methods/${action.payload.countryCode}`,
      { headers: { isPremium: !!action.payload.isPremium } }
    );

    yield put({ type: GET_PAYMENT_CHOICES_SUCCESS, payload: data });
  } catch (err) {
    console.log('Payment choices ERROR >>', err);
    yield put({
      type: GET_PAYMENT_CHOICES_ERROR,
      payload: i18n.t('paymentChoicesError'),
    });
  }
}

export function* sendCheckoutOrder(action: ISendOrderStartSaga) {
  try {
    yield put({ type: SEND_ORDER_START });

    const {
      data: { data },
    } = yield call(
      axios.post,
      `${process.env.REACT_APP_SERVICES_URL}​/orders/v1`,
      action.payload
    );

    yield put({ type: SEND_ORDER_SUCCESS });
    yield call(redirectToPayment, data.checkoutUrl);
  } catch (err) {
    console.log('Cart Order ERROR >>', err.response);
    yield put({
      type: SEND_ORDER_ERROR,
      payload: i18n.t('orderError'),
    });
  }
}

// Watchers
export function* watchGetShippingChoices() {
  yield takeLatest(GET_SHIPPING_PRICE_START_SAGA, getShippingPrice);
}

export function* watchGetPaymentChoices() {
  yield takeLatest(GET_PAYMENT_CHOICES_START_SAGA, getPaymentChoices);
}
export function* watchSendOrder() {
  yield takeLatest(SEND_ORDER_START_SAGA, sendCheckoutOrder);
}

// Export
export default function* allUserSagas() {
  yield all([
    watchGetShippingChoices(),
    watchGetPaymentChoices(),
    watchSendOrder(),
  ]);
}
