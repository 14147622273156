import React from 'react';
import { Steps } from 'antd';
import { useTranslation } from 'react-i18next';

const { Step } = Steps;

type StepType = {
  currentStep: number;
  stepTitles: string[];
};

const Stepper: React.FC<StepType> = ({ currentStep, stepTitles }) => {
  const { t } = useTranslation();
  const [first, second, third] = stepTitles;

  return (
    <Steps current={currentStep - 1}>
      <Step title={t(first)} />
      <Step title={t(second)} />
      <Step title={t(third)} />
    </Steps>
  );
};

export default Stepper;
