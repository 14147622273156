import * as React from "react"
import { IconType } from "lib/types"
import IconContainer from "components/Common/IconContainer"

function Facebook({ color, height, width }: IconType) {
  return (
    <IconContainer
      width={width}
      height={height}
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
      fillColor={color}
    >
      <path
        d="M288 176v-64c0-17.664 14.336-32 32-32h32V0h-64c-53.024 0-96 42.976-96 96v80h-64v80h64v256h96V256h64l32-80h-96z"
      />
    </IconContainer>
  )
}

export default Facebook
