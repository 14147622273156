import { IGetPaymentChoicesSuccessAction } from 'store/actions/checkout-actions/checkout-action-types';
import { ICheckout } from './index';

export const recalculatePaymentType = (
  state: ICheckout,
  action: IGetPaymentChoicesSuccessAction
) => {
  let type = state.paymentType;
  const pfound = action.payload.find((p) => type && p.id === type);
  let issuer = state.issuer;
  if (!pfound) {
    type = null;
    issuer = null;
  }
  return {
    paymentType: type,
    issuer,
  };
};
