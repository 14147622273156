import * as React from "react"
import { IconType } from "lib/types"
import IconContainer from "components/Common/IconContainer"

function Pinterest({ color, height, width }: IconType) {
  return (
    <IconContainer
      width={width}
      height={height}
      viewBox="0 0 390 512"
      xmlns="http://www.w3.org/2000/svg"
      fillColor={color}
    >
      <path
        d="M60.945 278.219c.641-1.598 7.051-26.231 7.5-27.899-10.007-15.058-21.125-64.371-12.597-104.398 9.199-58.73 71.437-87.602 130.199-87.602v-.11c73.57.048 128.641 40.981 128.699 111.478.047 55.179-33.195 128.117-89.957 128.117h-.043c-20.258 0-45.906-9.188-52.633-18.211-7.761-10.399-9.668-23.231-5.566-36.942 10.051-32.082 22.867-70.511 24.363-96.136 1.387-24.184-15.773-39.918-38.027-39.918-16.746 0-38.496 9.726-49.336 37.058-8.953 22.707-8.762 46.481.586 72.672 3.644 10.238-16.156 76.984-22.5 98.719-15.762 53.992-37.34 122.305-32.727 160.348L53.36 512l22.368-29.312c30.953-40.52 62.957-145.333 71.484-170.836 25.211 32.648 77.711 33.586 83.832 33.586 75.184 0 160.438-74.657 158.02-178.563C386.942 75.754 320.254 0 200.43 0v.117C86.453.117 19.93 60.953 3.645 137.82c-14.914 71.274 18.254 125.52 57.3 140.399z"
        fill={color}
      />
    </IconContainer>
  )
}

export default Pinterest
