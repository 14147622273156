import React, { useState, ReactElement, Fragment, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { H3, H4, Paragraph } from 'components/Common/Text';
import { AddressType, PremiumPlanType } from 'lib/types';
import { isEmpty as _isEmpty } from 'lodash';
import { Input, Popconfirm } from 'antd';
import { Button } from 'components/Common/Button';
import { getPremiumPlan } from 'store/actions/premium-plan-actions';
import {
  SafetyOutlined,
  EditOutlined,
  QuestionCircleOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import * as S from '../../Common';

// Types
type PriceDetailsTypes = {
  plan: PremiumPlanType;
  invoiceInfo?: AddressType;
  paymentType?: any;
  type: 'main' | 'checkout';
};

const PremiumPriceDetails: React.FC<PriceDetailsTypes> = ({
  invoiceInfo,
  plan,
  paymentType,
  type,
}): ReactElement => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { discounts, voucherResponse } = plan;

  const [voucherInput, setVoucherInput] = useState<string>('');

  const { voucherSuccessMessage, voucherErrorMessage } = useMemo(() => {
    switch (voucherResponse.status) {
      case 'NotFound':
        return {
          voucherErrorMessage: t('voucherNotFound'),
          voucherSuccessMessage: '',
        };
      case 'OK':
        return {
          voucherErrorMessage: '',
          voucherSuccessMessage: t('voucherSuccess'),
        };
      default:
        return {
          voucherErrorMessage: '',
          voucherSuccessMessage: '',
        };
    }
  }, [voucherResponse, t]);

  const renderPlan = () =>
    !_isEmpty(plan) && (
      <S.PriceDetailsListItem>
        <S.ListItem>{plan.priceDetailDescription.label}</S.ListItem>
        <S.ListItem>€ {plan.priceDetailDescription.value}</S.ListItem>
      </S.PriceDetailsListItem>
    );

  const renderDiscounts = () =>
    discounts.map((discount, index) => (
      <S.PriceDetailsListItem key={index}>
        <S.ListItem>
          {discount.isRemovable && (
            <S.RemoveVoucherWrap>
              <Popconfirm
                title={t('areYouSure')}
                onConfirm={() => removeVoucherHandler()}
                icon={<QuestionCircleOutlined style={{ color: 'indianred' }} />}
              >
                <DeleteOutlined />
              </Popconfirm>
            </S.RemoveVoucherWrap>
          )}
          <div>{discount.label}</div>
        </S.ListItem>
        <S.PriceWrapper>- €{discount.value}</S.PriceWrapper>
      </S.PriceDetailsListItem>
    ));

  const submitVoucherHandler = () => {
    dispatch(getPremiumPlan(plan.id, plan.type, voucherInput));
    dispatch({ type: 'RESET_CART' });
  };

  const removeVoucherHandler = () =>
    dispatch(getPremiumPlan(plan.id, plan.type));

  const renderPaymentType = () => {
    switch (paymentType) {
      case 'ideal':
        return 'Ideal';
      case 'creditcard':
        return 'Credit card';
      case 'paypal':
        return 'PayPal';
      case 'bancontact':
        return 'Bancontact';
      default:
        return '';
    }
  };

  return (
    <S.PriceDetailsWrap>
      <S.PriceDetailsInnerWrap>
        <S.PriceDetailsHeader>
          <H4>{t('priceDetails')}</H4>
        </S.PriceDetailsHeader>
        <S.PriceDetailsContent>
          <S.PriceDetailsList>
            {renderPlan()}
            {!_isEmpty(plan.discount) && plan.discount.value && (
              <S.PriceDetailsListItem>
                <S.ListItem>{plan.discount.label}</S.ListItem>
                <S.ListItem>- € {plan.discount.value}</S.ListItem>
              </S.PriceDetailsListItem>
            )}
            {renderDiscounts()}
            <S.PriceDetailsListItem>
              <S.ListItem bold>{plan.totalAmount.label}</S.ListItem>
              <S.ListItem bold>{plan.totalAmount.value}</S.ListItem>
            </S.PriceDetailsListItem>
          </S.PriceDetailsList>
        </S.PriceDetailsContent>
      </S.PriceDetailsInnerWrap>
      <S.SafePayments>
        <SafetyOutlined />
        <Paragraph>{t('safeSecurePayments')}</Paragraph>
      </S.SafePayments>
      <S.VoucherWrap>
        <Input
          type="text"
          placeholder={t('enterVoucherOrCard')}
          className={`${voucherErrorMessage ? 'hasError' : ''}`}
          onChange={(event) => setVoucherInput(event.target.value)}
          value={voucherInput}
        />
        <Button
          filled
          size="small"
          onClick={submitVoucherHandler}
          disabled={!voucherInput || voucherInput.length === 0}
        >
          {t('apply')}
        </Button>
      </S.VoucherWrap>
      {voucherErrorMessage && (
        <S.ErrorMessage>{voucherErrorMessage}</S.ErrorMessage>
      )}
      {voucherSuccessMessage && (
        <S.SuccessMessage>{voucherSuccessMessage}</S.SuccessMessage>
      )}
      {type === 'checkout' && (
        <Fragment>
          <S.DisplayAddresses>
            <S.PriceDetailsInnerWrap>
              <S.PriceDetailsHeader>
                <H4>{t('address')}</H4>
              </S.PriceDetailsHeader>
              <S.PriceDetailsContent>
                <H3>{t('invoiceAddress')}</H3>
                {invoiceInfo && (
                  <S.AddressListWrapper>
                    <S.AddressList>
                      <S.AddressListItem>
                        {invoiceInfo.firstName} {invoiceInfo.lastName}
                      </S.AddressListItem>
                      {invoiceInfo.company && (
                        <S.AddressListItem>
                          {invoiceInfo.company}
                        </S.AddressListItem>
                      )}
                      <S.AddressListItem>
                        {invoiceInfo.street} {invoiceInfo.houseNumber}
                      </S.AddressListItem>
                      <S.AddressListItem>
                        {invoiceInfo.zipCode} {invoiceInfo.city}
                      </S.AddressListItem>
                      <S.AddressListItem>
                        {invoiceInfo.countryName}
                      </S.AddressListItem>
                    </S.AddressList>
                    <EditOutlined
                      onClick={() =>
                        history.push(`/${i18n.language}/premium/1`)
                      }
                    />
                  </S.AddressListWrapper>
                )}
              </S.PriceDetailsContent>
            </S.PriceDetailsInnerWrap>
          </S.DisplayAddresses>

          <S.DisplayPayments>
            <S.PriceDetailsInnerWrap>
              <S.PriceDetailsHeader>
                <H4>{t('payments')}</H4>
              </S.PriceDetailsHeader>
              <S.PriceDetailsContent>
                <H3>{t('paymentMethod')}</H3>
                <Paragraph>{renderPaymentType()}</Paragraph>
              </S.PriceDetailsContent>
            </S.PriceDetailsInnerWrap>
            <EditOutlined
              onClick={() => history.push(`/${i18n.language}/premium/2`)}
            />
          </S.DisplayPayments>
        </Fragment>
      )}
    </S.PriceDetailsWrap>
  );
};

export default PremiumPriceDetails;
