import React from 'react';
import { Button } from 'components/Common/Button';
import { ShoppingCartOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import caretRight from 'assets/images/icons/caret_right_white.svg';
import caretLeft from 'assets/images/icons/caret_left_white.svg';
import styled from 'styled-components/macro';

const { REACT_APP_NAME } = process.env;

// Styled Components
const PaginationWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 10em;

  @media (max-width: 768px) {
    flex-direction: column;
    button {
      &:first-of-type {
        margin-bottom: 1.5em;
      }
    }
  }
`;

const Image = styled.img`
  width: 1em;
  height: 1em;
  display: inline-block;

  ${({ left }) =>
    left && {
      marginRight: '1em',
    }}
  ${({ right }) =>
    right && {
      marginLeft: '1em',
    }}
`;

// Types
type PaginationType = {
  currentView: number;
  clicked: (id: number) => void;
  history: any;
  sendOrder: () => void;
  disableNextBtn: boolean;
  paginationNames: string[];
  route: string;
};

const Pagination: React.FC<PaginationType> = ({
  currentView,
  clicked,
  history,
  sendOrder,
  disableNextBtn,
  paginationNames,
  route,
}) => {
  const { t, i18n } = useTranslation();

  const renderClicked = () =>
    currentView < 3 ? clicked(currentView + 1) : sendOrder();

  const renderNextIcon = () =>
    currentView === 3 ? (
      <ShoppingCartOutlined style={{ fontSize: '20px', marginLeft: '15px' }} />
    ) : (
      <Image right src={caretRight} alt={`${REACT_APP_NAME} - Caret Right`} />
    );

  return (
    <PaginationWrap step={currentView}>
      {currentView > 1 ? (
        <Button
          size="medium"
          filled
          disabled={currentView === 1}
          onClick={() => clicked(currentView - 1)}
        >
          <Image left src={caretLeft} alt={`${REACT_APP_NAME} - Caret Left`} />
          {t(paginationNames[currentView - 2])}
        </Button>
      ) : (
        <Button
          size="medium"
          filled
          onClick={() => history.push(`/${i18n.language}/${route}`)}
        >
          <Image left src={caretLeft} alt={`${REACT_APP_NAME} - Caret Left`} />{' '}
          {t('backShoppingCart')}
        </Button>
      )}

      <Button
        size="medium"
        filled
        onClick={() => renderClicked()}
        disabled={disableNextBtn}
      >
        {currentView === 3
          ? t('completeProcess')
          : t(paginationNames[currentView])}
        {renderNextIcon()}
      </Button>
    </PaginationWrap>
  );
};

export default Pagination;
