import { Container } from 'components/Common/Layout';
import { Button } from 'components/Common/Button';
import styled from 'styled-components/macro';
import React from 'react';
import { Paragraph } from 'components/Common/Text';
import { useTranslation } from 'react-i18next';
import { AppState } from 'store/reducers/rootReducer';
import { useSelector } from 'react-redux';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5em;

  button {
    width: 200px;
    margin-top: 2em;
  }
`;

const Heading = styled.h1`
  font-size: 4em;
  line-height: 1.2em;
  font-weight: 700;
  text-align: left;
  margin-bottom: 1em;

  @media (max-width: 768px) {
    text-align: center;
    font-size: 3em;
  }
`;

export const MobileSubscriptionPlan = () => {
  const { userAccount } = useSelector((state: AppState) => state.user);

  const {
    t,
    i18n: { language },
  } = useTranslation();

  const redirectDashboard = () => {
    window.location.href = `${process.env.REACT_APP_DASHBOARD_URL}/${language}/dashboard`;
  };

  return (
    <Container>
      <Wrapper>
        <Heading>{t('mobilePlanText')}</Heading>
        <Paragraph bold>
          {t('hasMobilePlan', { mobileStore: userAccount?.registrationSource })}
        </Paragraph>
        <Button filled size="medium" onClick={redirectDashboard}>
          {t('goBackToDashboard')}
        </Button>
      </Wrapper>
    </Container>
  );
};
