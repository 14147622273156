import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import styled from 'styled-components/macro';
import { Button } from 'components/Common/Button';
import { DiaryProduct } from 'lib/types';
import ProductCardSmall from 'components/Views/ProductView/Products/ProductCardSmall';
import { useHistory } from 'react-router-dom';

const ModalHeading = styled.div`
  display: inline-block;
  margin: 0;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .mdi-icon {
    display: block;
    height: 3em;
    width: 3em;
    color: ${({ theme }) => theme.primaryColor};
  }
`;

const ButtonGroupWrap = styled.div`
  display: flex;
  margin-top: 1rem;
  width: 100%;
  justify-content: space-evenly;
`;

const DescriptionText = styled.p`
  font-size: 0.95rem;
  font-weight: 600;
`;

const NoteText = styled.p`
  font-size: 0.95rem;
  font-style: italic;
`;

type ShipmentErrorType = {
  diaryId: string;
  diaryName: string;
};

type ShipmentWarningModalType = {
  error: ShipmentErrorType[] | null;
  diariesInBasket: DiaryProduct[];
  onConfirm: () => void;
};

const ShipmentWarningModal: React.FC<ShipmentWarningModalType> = ({
  error,
  diariesInBasket,
  onConfirm,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const history = useHistory();

  if (!error) return null;

  const diaryIds = error.map((err) => err.diaryId);
  const filteredItems = diariesInBasket.filter((item) =>
    diaryIds.includes(item.id)
  );

  return (
    <Modal
      style={{ top: '50%', transform: 'translateY(-50%)' }}
      title={
        <ModalHeading style={{ padding: '10px 0 !important' }}>
          {t('shipmentWarning')}
        </ModalHeading>
      }
      closable={false}
      visible={true}
      width={'600px'}
      bodyStyle={{ width: '100%', maxHeight: '80vh', overflowY: 'scroll' }}
      footer={''}
      maskClosable={false}
    >
      <ModalContent>
        <DescriptionText>{t('shipmentWarningDescription')}</DescriptionText>
        <NoteText>{t('shipmentWarningNote')}</NoteText>
        {filteredItems.map((item) => (
          <ProductCardSmall {...item} />
        ))}

        <ButtonGroupWrap>
          <Button
            size="medium"
            outlined
            onClick={() => {
              onConfirm();
              history.push(`/${language}/cart`);
            }}
          >
            {t('backShoppingCart')}
          </Button>
        </ButtonGroupWrap>
      </ModalContent>
    </Modal>
  );
};

export default ShipmentWarningModal;
