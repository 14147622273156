import React, { ReactElement, useState, useEffect } from 'react';
import { DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { H4, Paragraph } from 'components/Common/Text';
import { Popconfirm } from 'antd';
import { Divider } from 'components/Common/Layout';
import { DiaryProduct } from 'lib/types';
import { isEmpty as _isEmpty, reduce as _reduce } from 'lodash';
import { notAvailableImage } from 'lib/data.json';
import QuantityPicker from 'components/Common/QuantityPicker';
import CoverLoader from 'components/Common/CoverLoader';
import axios from 'axios';
import styled from 'styled-components/macro';

// Styled Components

const ProductCardWrap = styled.div`
  border: 1px solid #ededed;
  -webkit-box-shadow: rgb(227, 227, 227) 1em 1em 2em 0px;
  -moz-box-shadow: rgb(227, 227, 227) 1em 1em 2em 0px;
  box-shadow: rgb(227, 227, 227) 1em 1em 2em 0px;
  -webkit-border-radius: 1.5em;
  -moz-border-radius: 1.5em;
  border-radius: 1.5em;
  margin-bottom: 3em;
`;

const ProductCardInnerWrap = styled.div`
  padding: 1em;
  display: flex;
  flex-direction: row;
  position: relative;

  @media (max-width: 550px) {
    flex-direction: column;
  }
`;

const ImageWrap = styled.div`
  width: 16em;
  height: auto;
  min-height: 20em;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  position: relative;
  border: 1px solid #f6f6f6;

  p {
    margin-bottom: 0;
    text-align: center;
    font-size: 1.4em;
    margin-top: 5px;
  }

  ${({ type }) =>
    type === 'Pdf' && {
      alignItems: 'center',
      justifyContent: 'center',
    }}

  img {
    ${({ type }) =>
      type === 'Pdf' && {
        width: '75%',
        height: '75%',
        border: 0,
        boxShadow: 'none',
      }}
  }

  @media (max-width: 550px) {
    width: 100%;
    height: auto;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  -webkit-border-radius: 0.35em;
  -moz-border-radius: 0.35em;
  border-radius: 0.35em;
  border: 1px solid #f6f6f6;
  -webkit-transition: 0.75s all ease-in-out;
  -moz-transition: 0.75s all ease-in-out;
  transition: 0.75s all ease-in-out;
  opacity: ${({ hasLoaded }) => (hasLoaded ? 1 : 0)};

  @media (max-width: 600px) {
    width: 100%;
    height: 100%;
  }
`;

const InfoWrap = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  padding: 1em 1.75em;

  @media (max-width: 550px) {
    position: relative;
    padding: 2em 1em;
  }
`;

const DiaryInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const TitleWrap = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 3em;

  h4 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    margin-bottom: 0;
    width: 95%;
  }
`;

const DiaryOverviewWrap = styled.div`
  margin-top: auto;

  p {
    margin-bottom: 0.75em;
    font-size: 1.6em;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

const PriceQuantityWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const DeleteIconWrap = styled.div`
  position: absolute;
  padding: 0.5em;
  right: 2.7em;
  top: 1.75em;
  border: 1px solid #ededed;
  -webkit-border-radius: 0.5em;
  -moz-border-radius: 0.5em;
  border-radius: 0.5em;
  cursor: pointer;
  -webkit-transition: 0.05s all linear;
  -moz-transition: 0.05s all linear;
  transition: 0.05s all linear;

  .anticon {
    color: indianred;
    font-size: 2em;
  }

  &:hover {
    background: indianred;
    border-color: indianred;
    .anticon {
      color: white;
    }
  }

  @media (max-width: 992px) {
    display: none;
  }
`;

const DeleteIconWrapMobile = styled.div`
  padding: 0.5em;
  border: 1px solid #ededed;
  -webkit-border-radius: 0.5em;
  -moz-border-radius: 0.5em;
  border-radius: 0.5em;
  cursor: pointer;
  -webkit-transition: 0.05s all linear;
  -moz-transition: 0.05s all linear;
  transition: 0.05s all linear;
  display: none;

  .anticon {
    color: indianred;
    font-size: 2em;
  }

  &:hover {
    background: indianred;
    border-color: indianred;
    .anticon {
      color: white;
    }
  }

  @media (max-width: 992px) {
    display: block;
  }
`;

const Quantity = styled.div`
  width: auto;
  font-size: 1.6em;
  position: relative;
  top: -2px;

  p {
    display: inline-block;
    font-size: 16px;
  }

  @media (max-width: 400px) {
    p {
      display: none;
    }
  }
`;

// Types

type DiarySetQuantity = {
  setQuantity?: (
    diaryId: string,
    quantity: number
  ) => { diaryId: string; quantity: number };
  deleteCartItem?: (diaryId: string, name: string) => void;
};

// const getProductSize = (properties: any) => {
//   const size = properties.length
//     ? properties[0].value.split('/')[0].trim()
//     : '';
//   switch (size) {
//     case 'S':
//       return '1';
//     case 'M':
//       return '2';
//     case 'L':
//       return '3';
//     default:
//       return '';
//   }
// };

const ProductCard: React.FC<DiaryProduct & DiarySetQuantity> = ({
  id,
  imageUrl,
  name,
  price,
  properties,
  quantity,
  setQuantity,
  maxQuantity,
  deleteCartItem,
  productType,
}): ReactElement => {
  const { t } = useTranslation();
  const [diaryProperties, setDiaryProperties] = useState<any>({});
  const [imageLoaded, setImageLoaded] = useState<boolean>(false);
  const [imageResponse, setImageResponse] = useState<string>('');

  useEffect(() => {
    if (!_isEmpty(properties)) {
      const reducedProperties = _reduce(
        properties,
        (obj, item, index) => {
          obj[index] = {
            title: item.key,
            content: item.value,
          };
          return obj;
        },
        {}
      );

      setDiaryProperties(reducedProperties);
    }
  }, [properties]);

  const renderDiaryFormat = (): boolean | JSX.Element =>
    !_isEmpty(diaryProperties) && (
      <Paragraph>
        {diaryProperties[0].title}: {diaryProperties[0].content}
      </Paragraph>
    );

  const renderDiaryPages = (): boolean | JSX.Element =>
    !_isEmpty(diaryProperties) && (
      <Paragraph>
        {diaryProperties[1].title}: {diaryProperties[1].content}
      </Paragraph>
    );

  const deleteItem = (diaryId: string, name: string) => {
    if (deleteCartItem) {
      deleteCartItem(diaryId, name);
    }
  };

  const fetchDiaryCover = async (imageUrl: string): Promise<any> => {
    // const productSize = getProductSize(properties);
    const productSize = 1; // temporarily show S size cover for products
    try {
      let response = await axios.get(
        `${imageUrl}${productSize && `?product_size=${productSize}`}`,
        {
          responseType: 'arraybuffer',
        }
      );

      const [imageResponse] = await Promise.all([
        response.status === 200
          ? `data:${response.headers['content-type']};base64,${Buffer.from(
              response.data,
              'binary'
            ).toString('base64')}`
          : notAvailableImage,
      ]);

      setImageResponse(imageResponse);
    } catch (err) {
      setImageResponse(notAvailableImage);
    }
  };

  useEffect(() => {
    renderDiaryImage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageUrl]);

  const renderDiaryImage = () =>
    productType === 'Pdf'
      ? setImageResponse(imageUrl)
      : fetchDiaryCover(imageUrl);

  return (
    <ProductCardWrap>
      <ProductCardInnerWrap>
        <DeleteIconWrap>
          <Popconfirm
            title={t('areYouSure')}
            onConfirm={() => deleteItem(id, name)}
            icon={<QuestionCircleOutlined style={{ color: 'indianred' }} />}
          >
            <DeleteOutlined />
          </Popconfirm>
        </DeleteIconWrap>
        <ImageWrap type={productType}>
          {!imageLoaded && <CoverLoader />}
          {imageResponse && (
            <Image
              src={imageResponse && imageResponse}
              onLoad={() => setImageLoaded(true)}
              hasLoaded={imageLoaded}
            />
          )}
        </ImageWrap>
        <InfoWrap>
          <DiaryInfo>
            <TitleWrap>
              <H4>{name}</H4>
              <DeleteIconWrapMobile>
                <Popconfirm
                  placement="leftBottom"
                  title={t('areYouSure')}
                  cancelText={t('cancel')}
                  onConfirm={() => deleteItem(id, name)}
                  icon={
                    <QuestionCircleOutlined style={{ color: 'indianred' }} />
                  }
                >
                  <DeleteOutlined />
                </Popconfirm>
              </DeleteIconWrapMobile>
            </TitleWrap>
            <DiaryOverviewWrap>
              {renderDiaryFormat()}
              {renderDiaryPages()}
              <Divider />
              <PriceQuantityWrap>
                <Paragraph>
                  {t('price')}: <b>€ {price.price.toFixed(2)}</b>
                </Paragraph>
                <Quantity>
                  <Paragraph>{t('quantity')}:</Paragraph>
                  <QuantityPicker
                    maxQuantity={maxQuantity}
                    currentQuantity={quantity}
                    setQuantityRequest={setQuantity as any}
                    id={id}
                  />
                </Quantity>
              </PriceQuantityWrap>
            </DiaryOverviewWrap>
          </DiaryInfo>
        </InfoWrap>
      </ProductCardInnerWrap>
    </ProductCardWrap>
  );
};

export default ProductCard;
