import { PremiumOrderType, PremiumPlanType } from 'lib/types';

export const GET_PREMIUM_START_SAGA = 'GET_PREMIUM_START_SAGA';
export const GET_PREMIUM_START = 'GET_PREMIUM_START';
export const GET_PREMIUM_SUCCESS = 'GET_PREMIUM_SUCCESS';
export const GET_PREMIUM_ERROR = 'GET_PREMIUM_ERROR';
export const SET_PREMIUM_PLAN = 'SET_PREMIUM_PLAN';
export const REMOVE_PREMIUM_PLAN = 'REMOVE_PREMIUM_PLAN';

export const SEND_PREMIUM_ORDER_START_SAGA = 'SEND_PREMIUM_ORDER_START_SAGA';
export const SEND_PREMIUM_ORDER_START = 'SEND_PREMIUM_ORDER_START';
export const SEND_PREMIUM_ORDER_SUCCESS = 'SEND_PREMIUM_ORDER_SUCCESS';
export const SEND_PREMIUM_ORDER_ERROR = 'SEND_PREMIUM_ORDER_ERROR';

export interface IGetPremiumStartSagaAction {
  type: typeof GET_PREMIUM_START_SAGA;
  payload: { id: string; type: string; voucherCode?: string };
}

export interface IGetPremiumStartAction {
  type: typeof GET_PREMIUM_START;
}

export interface IGetPremiumSuccessAction {
  type: typeof GET_PREMIUM_SUCCESS;
  payload: PremiumPlanType;
}

export interface IGetPremiumErrorAction {
  type: typeof GET_PREMIUM_ERROR;
  payload: 409 | 500;
}
export interface ISetPremiumPlan {
  type: typeof SET_PREMIUM_PLAN;
  payload: PremiumPlanType;
}

export interface IRemovePremiumPlan {
  type: typeof REMOVE_PREMIUM_PLAN;
  payload: string;
}

export interface ISendPremiumStartSagaAction {
  type: typeof SEND_PREMIUM_ORDER_START_SAGA;
  payload: PremiumOrderType;
}

export interface ISendPremiumStartAction {
  type: typeof SEND_PREMIUM_ORDER_START;
}

export interface ISendPremiumSuccessAction {
  type: typeof SEND_PREMIUM_ORDER_SUCCESS;
  payload: string;
}

export interface ISendPremiumErrorAction {
  type: typeof SEND_PREMIUM_ORDER_ERROR;
  payload: string;
}

export type PremiumPlansActionTypes =
  | IGetPremiumStartSagaAction
  | IGetPremiumStartAction
  | IGetPremiumSuccessAction
  | IGetPremiumErrorAction
  | ISetPremiumPlan
  | IRemovePremiumPlan
  | ISendPremiumStartSagaAction
  | ISendPremiumStartAction
  | ISendPremiumSuccessAction
  | ISendPremiumErrorAction;
