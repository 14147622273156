import React from 'react';
import { useTranslation } from 'react-i18next';
import { Paragraph } from 'components/Common/Text';
import { Container } from 'components/Common/Layout';
import { copyrightFooterLinks, socialLinks } from 'lib/data.json';
import styled from 'styled-components/macro';
import { appName } from 'hoc/Layout';

// Social Icons
import Twitter from 'components/Icons/Twitter';
import Instagram from 'components/Icons/Instagram';
import Facebook from 'components/Icons/Facebook';
import Pinterest from 'components/Icons/Pinterest';

const { REACT_APP_NAME } = process.env;

const Icons = {
  Twitter: Twitter,
  Instagram: Instagram,
  Facebook: Facebook,
  Pinterest: Pinterest,
};

const FooterContainer = styled.footer`
  background: #f7f7f7;
  border-top: #f6f6f6;

  @media (max-width: 768px) {
    font-size: 14px !important;
  }
`;

const FooterInnerWrap = styled.div`
  padding: 2em 0;
  border-top: 1px solid #ededed;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  p {
    font-size: 14px;
    margin-bottom: 0;

    span {
      display: inline-block;
      width: 1.5em;
      text-align: center;
    }
  }

  @media (max-width: 992px) {
    flex-flow: column wrap;
    text-align: center;
  }
`;

const FooterLink = styled.a`
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
  font-size: 14px;
  color: ${({ theme }) => theme.textColor};
  transition: 0.1s color ease-in-out;
  font-weight: 300;

  &:hover {
    color: ${({ theme }) => theme.primaryHover};
  }

  &:after {
    content: '·';
    display: inline-block;
    width: 1.5em;
    text-align: center;
  }

  &:last-of-type:after {
    display: none;
  }
`;

const FooterSocialIcons = styled.div`
  @media (max-width: 992px) {
    margin-top: 1.5em;
  }
`;

const FooterSocialLink = styled.a`
  display: inline-block;
  margin-right: 1.8em;
  cursor: pointer;

  img {
    width: 1.7em;
    height: 1.7em;
    vertical-align: baseline;

    @media (max-width: 768px) {
      width: 1.5em;
      height: 1.5em;
    }
  }
`;

export const renderSocialIcons = (height: string, width: string) =>
  socialLinks.length > 0 &&
  socialLinks.map((item) => {
    const SocialIcon = Icons[item.component];
    if (SocialIcon)
      return (
        <FooterSocialLink
          href={item.href}
          rel="noopener noreferrer"
          target="_blank"
          key={item.href}
        >
          <SocialIcon
            height={height}
            width={width}
            color={({ theme }) => theme.primaryColor}
          />
        </FooterSocialLink>
      );
    else return null;
  });

const Footer = () => {
  const { i18n } = useTranslation();
  const lang = i18n.language.toLowerCase();

  const renderCopyrightFooterLinks = () => {
    const links = copyrightFooterLinks[appName];

    return links[lang].map((item) => (
      <FooterLink
        href={`${process.env.REACT_APP_WEBSITE_URL}/${lang}/${item.href}`}
        rel="noopener noreferrer"
        target="_blank"
        key={item.title}
      >
        {item.title}
      </FooterLink>
    ));
  };

  return (
    <FooterContainer>
      <Container>
        <FooterInnerWrap>
          <Paragraph>
            @ {new Date().getFullYear()} {REACT_APP_NAME}, All rights reserved{' '}
            <span>·</span>
            {renderCopyrightFooterLinks()}
          </Paragraph>
          <FooterSocialIcons>{renderSocialIcons('16', '16')}</FooterSocialIcons>
        </FooterInnerWrap>
      </Container>
    </FooterContainer>
  );
};

export default Footer;
