import {
  IGetCartStartAction,
  IRemoveVoucherStartSaga,
  IUpdateReceiptStartSagaAction,
  REMOVE_VOUCHER_ERROR,
  REMOVE_VOUCHER_START,
  REMOVE_VOUCHER_START_SAGA,
  REMOVE_VOUCHER_SUCCESS,
  UPDATE_RECEIPT_ERROR,
  UPDATE_RECEIPT_START_SAGA,
  UPDATE_RECEIPT_SUCCESS,
  UPDATE_RECEIPT_START,
} from './../../actions/cart-actions/cart-action-types';
import { put, takeLatest, call, all, delay } from 'redux-saga/effects';
import axios from 'axios';
import {
  GET_CART_START_SAGA,
  GET_CART_START,
  GET_CART_SUCCESS,
  GET_CART_ERROR,
  SET_CART_QUANTITY_START_SAGA,
  SET_CART_QUANTITY_START,
  SET_CART_QUANTITY_SUCCESS,
  SET_CART_QUANTITY_ERROR,
  DELETE_CART_ITEM_START_SAGA,
  DELETE_CART_ITEM_START,
  DELETE_CART_ITEM_SUCCESS,
  DELETE_CART_ITEM_ERROR,
  POST_VOUCHER_START_SAGA,
  POST_VOUCHER_START,
  POST_VOUCHER_SUCCESS,
  POST_VOUCHER_ERROR,
  RESET_CART,
  ISetCartQuantityStartSagaAction,
  IDeleteCartItemStartSagaAction,
  IPostVoucherStartSaga,
} from 'store/actions/cart-actions/cart-action-types';
import i18n from 'i18n';

// Sagas
export function* getCartData(action: IGetCartStartAction) {
  try {
    yield put({ type: GET_CART_START });

    const {
      data: { data },
    } = yield call(
      axios.get,
      `${process.env.REACT_APP_SERVICES_URL}/basket/v1?isPreview=${action.payload}`
    );

    yield put({
      type: GET_CART_SUCCESS,
      payload: { diariesInBasket: data.items, receipt: data.receipt },
    });
  } catch (err) {
    console.log('ERROR GET CART >>>', err);
    yield put({ type: GET_CART_ERROR });
  }
}
export function* setCartQuantityData(action: ISetCartQuantityStartSagaAction) {
  try {
    yield put({ type: SET_CART_QUANTITY_START });

    const {
      data: { data },
    } = yield call(
      axios.put,
      `${process.env.REACT_APP_SERVICES_URL}/basket/v1/${action.payload.itemId}`,
      { quantity: action.payload.quantity }
    );

    yield put({
      type: SET_CART_QUANTITY_SUCCESS,
      payload: { diariesInBasket: data.items, receipt: data.receipt },
    });
  } catch (err) {
    console.log('ERROR SET CART QUANTITY >>>', err);
    yield put({ type: SET_CART_QUANTITY_ERROR });
  }
}
export function* deleteCartItemData(action: IDeleteCartItemStartSagaAction) {
  try {
    yield put({ type: DELETE_CART_ITEM_START });

    const {
      data: { data },
    } = yield call(
      axios.delete,
      `${process.env.REACT_APP_SERVICES_URL}/basket/v1/${action.payload.itemId}`
    );
    yield put({
      type: DELETE_CART_ITEM_SUCCESS,
      payload: {
        diariesInBasket: data.items,
        receipt: data.receipt,
        name: action.payload.name,
      },
    });

    yield delay(1000);
    yield put({ type: RESET_CART });
  } catch (err) {
    console.log('ERROR DELETE CART ITEM >>>', err.response);
    yield put({ type: DELETE_CART_ITEM_ERROR });
    yield delay(1000);
    yield put({ type: RESET_CART });
  }
}

export function* applyVoucher(action: IPostVoucherStartSaga) {
  try {
    yield put({ type: POST_VOUCHER_START });

    const { data } = yield call(
      axios.post,
      `${process.env.REACT_APP_SERVICES_URL}/basket/v1/voucher`,
      { code: action.payload.code }
    );

    if (!data.data.successfully) {
      yield put({ type: POST_VOUCHER_ERROR, payload: data.data.errorCode });
    } else
      yield put({
        type: POST_VOUCHER_SUCCESS,
        payload: {
          diariesInBasket: data.data.basket.items,
          receipt: data.data.basket.receipt,
          successMessage: action.payload.successMessage,
        },
      });
  } catch (err) {
    console.log('POST VOUCHER ERROR >>', err.response);
    if (err.response.status === 500 || err.response.status === 404)
      yield put({ type: POST_VOUCHER_ERROR, payload: 'basketNotFound' });
  }
}

export function* removeVoucher(action: IRemoveVoucherStartSaga) {
  try {
    yield put({ type: REMOVE_VOUCHER_START });

    const { data } = yield call(
      axios.delete,
      `${process.env.REACT_APP_SERVICES_URL}/basket/v1/voucher/${action.payload.code}`
    );

    if (!data.data.successfully) {
      yield put({ type: REMOVE_VOUCHER_ERROR, payload: data.data.error });
    } else
      yield put({
        type: REMOVE_VOUCHER_SUCCESS,
        payload: {
          diariesInBasket: data.data.basket.items,
          receipt: data.data.basket.receipt,
          successMessage: action.payload.successMessage,
        },
      });
  } catch (err) {
    console.log('POST VOUCHER ERROR >>', err.response);
    if (err.response.status === 500 || err.response.status === 404)
      yield put({ type: REMOVE_VOUCHER_ERROR, payload: 'basketNotFound' });
  }
}

export function* updateReceipt(action: IUpdateReceiptStartSagaAction) {
  try {
    yield put({ type: UPDATE_RECEIPT_START });

    const {
      data: { data },
    } = yield call(
      axios.post,
      `${process.env.REACT_APP_SERVICES_URL}/basket/v1/shipment-info`,
      action.payload
    );

    yield put({ type: UPDATE_RECEIPT_SUCCESS, payload: data.receipt });
  } catch (err) {
    console.log('ERROR SEND SHIPPING TYPE >>>', err);
    yield put({
      type: UPDATE_RECEIPT_ERROR,
      payload: i18n.t('shippingTypeError'),
    });
  }
}

// Watchers
export function* watchGetCartData() {
  yield takeLatest(GET_CART_START_SAGA, getCartData);
}

export function* watchSetCartQuantityData() {
  yield takeLatest(SET_CART_QUANTITY_START_SAGA, setCartQuantityData);
}

export function* watchDeleteCartItemData() {
  yield takeLatest(DELETE_CART_ITEM_START_SAGA, deleteCartItemData);
}

export function* watchApplyVoucher() {
  yield takeLatest(POST_VOUCHER_START_SAGA, applyVoucher);
}
export function* watchRemoveVoucher() {
  yield takeLatest(REMOVE_VOUCHER_START_SAGA, removeVoucher);
}

export function* watchUpdateReceipt() {
  yield takeLatest(UPDATE_RECEIPT_START_SAGA, updateReceipt);
}

// Export
export default function* allUserSagas() {
  yield all([
    watchGetCartData(),
    watchSetCartQuantityData(),
    watchDeleteCartItemData(),
    watchApplyVoucher(),
    watchRemoveVoucher(),
    watchUpdateReceipt(),
  ]);
}
