import React, { useEffect, ReactElement } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from 'store/reducers/rootReducer';
import { message } from 'antd';
import { isEmpty as _isEmpty } from 'lodash';
import Products from 'components/Views/ProductView/Products';
import PriceDetails from 'components/Views/ProductView/PriceDetails';
import LoadingScreen from 'components/Common/LoadingScreen';
import { useTranslation } from 'react-i18next';
import { H3 } from 'components/Common/Text';
import { Button } from 'components/Common/Button';
import * as S from 'components/Stepper/styles';

const ProductStepThree: React.FC = (): ReactElement => {
  const dispatch = useDispatch();
  const {
    user,
    deliveryInfo,
    invoiceInfo,
    withDeliveryAddress: withInvoiceAddress,
  } = useSelector((state: AppState) => state.user);
  const { t, i18n } = useTranslation();
  const lang = i18n.language.toLowerCase();

  const { diariesInBasket, receipt, deletedItem, error } = useSelector(
    (state: AppState) => state.cart
  );
  const { paymentType, isOrderSending } = useSelector(
    (state: AppState) => state.checkout
  );

  useEffect(() => {
    if (_isEmpty(deliveryInfo)) {
      return;
    }
    // We need to update the receipt with the selected country and state
    window.scrollTo(0, 0);
  }, [dispatch, deliveryInfo]);

  useEffect(() => {
    if (!_isEmpty(deletedItem)) {
      message.success({
        content: t('cartItemRemoved', { deletedItem }),
        style: {
          marginTop: '45px',
        },
      });
    }
  }, [deletedItem, t]);

  useEffect(() => {
    if (!_isEmpty(error)) {
      message.error({
        content: error,
        style: {
          marginTop: '75px',
        },
      });
    }
  }, [error]);

  const redirectDashboard = (endpoint: string) => {
    window.location.href = `${process.env.REACT_APP_DASHBOARD_URL}/${lang}/${endpoint}`;
  };

  return (
    <>
      {_isEmpty(user) || isOrderSending ? (
        <LoadingScreen />
      ) : !_isEmpty(diariesInBasket) ? (
        <S.MainViewComponent>
          <Products diariesInBasket={diariesInBasket} />
          <PriceDetails
            receipt={receipt}
            diariesInBasket={diariesInBasket}
            withInvoiceAddress={withInvoiceAddress}
            deliveryInfo={deliveryInfo}
            invoiceInfo={invoiceInfo}
            paymentType={paymentType}
            type="checkout"
          />
        </S.MainViewComponent>
      ) : (
        <S.ItemsCTAWrap>
          <H3>{t('emptyCart')}</H3>
          <Button
            size="medium"
            filled
            onClick={() => redirectDashboard('dashboard')}
          >
            {t('goBackToDashboard')}
          </Button>
        </S.ItemsCTAWrap>
      )}
    </>
  );
};

export default ProductStepThree;
