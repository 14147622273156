import {
  CheckoutActionTypes,
  GET_PAYMENT_CHOICES_START,
  GET_PAYMENT_CHOICES_SUCCESS,
  GET_PAYMENT_CHOICES_ERROR,
  SET_ISSUER,
  SET_PAYMENT_TYPE,
  SEND_ORDER_START,
  SEND_ORDER_SUCCESS,
  SEND_ORDER_ERROR,
  GET_SHIPPING_PRICE_START,
  GET_SHIPPING_PRICE_SUCCESS,
  GET_SHIPPING_PRICE_ERROR,
  SET_SHIPMENT_ERROR,
} from 'store/actions/checkout-actions/checkout-action-types';
import { PaymentChoice } from 'lib/types';
import { recalculatePaymentType } from './checkout-reducer-utilities';

export interface ICheckout {
  paymentType: string | null;
  issuer: string | null;
  paymentChoices: PaymentChoice[];
  isLoading: boolean;
  isOrderSending: boolean;
  errorCheckout: null | string;
  shipmentError: null | [];
  shippingPrice: undefined | string;
}

const paymentType = localStorage.getItem('paymentType');
let issuer = localStorage.getItem('issuer');
if (issuer && !paymentType) issuer = null;

const initialState: ICheckout = {
  paymentType,
  issuer,
  paymentChoices: [],
  isLoading: false,
  isOrderSending: false,
  errorCheckout: null,
  shipmentError: null,
  shippingPrice: undefined,
};

const CheckoutReducer = (
  state: ICheckout = initialState,
  action: CheckoutActionTypes
): ICheckout => {
  switch (action.type) {
    case SET_PAYMENT_TYPE:
      return {
        ...state,
        paymentType: action.payload,
      };
    case SET_ISSUER:
      return {
        ...state,
        issuer: action.payload,
      };
    case GET_PAYMENT_CHOICES_START:
      return {
        ...state,
        isLoading: true,
      };
    case GET_PAYMENT_CHOICES_SUCCESS:
      return {
        ...state,
        paymentChoices: action.payload,
        isLoading: false,
        ...recalculatePaymentType(state, action),
      };
    case GET_PAYMENT_CHOICES_ERROR:
      return {
        ...state,
        isLoading: false,
        errorCheckout: action.payload,
        paymentType: null,
      };
    case SEND_ORDER_START:
      return {
        ...state,
        isOrderSending: true,
      };
    case SEND_ORDER_SUCCESS:
      return {
        ...state,
        isOrderSending: false,
      };
    case SEND_ORDER_ERROR:
      return {
        ...state,
        isOrderSending: false,
        errorCheckout: action.payload,
      };
    case GET_SHIPPING_PRICE_START:
      return {
        ...state,
        isLoading: true,
        errorCheckout: '',
      };
    case GET_SHIPPING_PRICE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        shippingPrice: action.payload.shippingPrice,
        shipmentError: action.payload.isSuccess
          ? null
          : action.payload.errorMessages,
      };
    case GET_SHIPPING_PRICE_ERROR:
      return {
        ...state,
        isOrderSending: false,
        shippingPrice: undefined,
        errorCheckout: action.payload,
      };
    case SET_SHIPMENT_ERROR:
      return {
        ...state,
        shipmentError: action.payload,
      };
    default:
      return state;
  }
};

export default CheckoutReducer;
