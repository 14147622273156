import React from "react";
import styled from "styled-components/macro";

const LoaderWrapper = styled.div`
	position: fixed;
	background: #ffffff;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 9999999999999;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const LoaderInnerWrap = styled.div`
	display: inline-block;
	position: fixed;
	width: 80px;
	height: 80px;
`;

const LoaderInnerWrapChild = styled.div`
	display: block;
	position: absolute;
	width: 64px;
	height: 64px;
	margin: 8px;
	border: 8px solid ${({ theme }) => theme.primaryColor};
	border-radius: 50%;
	-webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	-moz-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: ${({ theme }) => theme.primaryColor} transparent transparent transparent;

	&:nth-child(1) {
		-webkit-animation-delay: -0.45s;
		-moz-animation-delay: -0.45s;
		animation-delay: -0.45s;
	}

	&:nth-child(2) {
		-webkit-animation-delay: -0.3s;
		-moz-animation-delay: -0.3s;
		animation-delay: -0.3s;
	}

	&:nth-child(3) {
		-webkit-animation-delay: -0.15s;
		-moz-animation-delay: -0.15s;
		animation-delay: -0.15s;
	}

	@keyframes lds-ring {
		0% {
			-webkit-transform: rotate(0deg);
			-moz-transform: rotate(0deg);
			transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
			-moz-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}
`;

const Loader: React.FC = () => {
	return (
		<LoaderWrapper>
			<LoaderInnerWrap>
				<LoaderInnerWrapChild></LoaderInnerWrapChild>
				<LoaderInnerWrapChild></LoaderInnerWrapChild>
				<LoaderInnerWrapChild></LoaderInnerWrapChild>
				<LoaderInnerWrapChild></LoaderInnerWrapChild>
			</LoaderInnerWrap>
		</LoaderWrapper>
	);
};

export default Loader;
