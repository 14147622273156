import React, { Fragment } from "react";
import styled, { ThemeProvider, createGlobalStyle } from "styled-components/macro";
import Header from "components/Header";
import Footer from "components/Footer";
import theme from "lib/theme";

export const appName = process.env.REACT_APP_NAME || "Travel Diaries";

const appTheme = theme[appName.toString()];

const GlobalStyles = createGlobalStyle`
    :root {
        --primary-color: ${({ theme }) => theme.primaryColor};
    }
    
 * {
        padding: 0;
        margin: 0;
        min-height: 0;
        min-width: 0;
        box-sizing: border-box;
    }

    html {
        scroll-behavior: smooth;
        margin: 0;
        padding: 0;
    }

    body {
        font-family: "Nunito", sans-serif;
        scroll-behavior: smooth;
        overflow-x: hidden;
        box-sizing: border-box;
        font-size: 10px;
        line-height: 1;
        margin: 0;
        padding: 0;
    }

    ::-webkit-scrollbar {
        width: 10px;
    }

    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 10px #e0e0e0;
    }

    ::-webkit-scrollbar-thumb {
        background: ${({ theme }) => theme.primaryColor};
    }

    ::-webkit-scrollbar-thumb:hover {
        background: ${({ theme }) => theme.disabledColor};
    }

`;

const LayoutWrapper = styled.div`
	padding: 7.5em 0 10em;
    min-height: calc(100vh - 15em);

	@media (max-width: 768px) {
		padding: 12em 0 5.5em;
	}
`;


const Layout = ({ children }) => (
	<Fragment>
		<ThemeProvider theme={appTheme}>
			<GlobalStyles />
			<Header />
			<LayoutWrapper>{children}</LayoutWrapper>
			<Footer />
		</ThemeProvider>
	</Fragment>
);

export default Layout;
