import React, { ReactElement } from 'react';
import { PremiumPlanType } from 'lib/types';
import styled from 'styled-components/macro';
import { Popconfirm } from 'antd';
import { Divider } from 'components/Common/Layout';
import { DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import * as S from '../../Common';
import { useTranslation } from 'react-i18next';
import { H4, Paragraph } from 'components/Common/Text';
import { useDispatch } from 'react-redux';
import { removePremiumItem } from 'store/actions/premium-plan-actions';

// Styled Components

const PlanWrap = styled.div`
  width: 60%;
  padding: 0 1.5em;
  display: flex;
  flex-direction: column;

  @media (max-width: 992px) {
    width: 100%;
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 5em;
  }
`;

// Types

type PlanProps = {
  plan: PremiumPlanType;
};

const Plan: React.FC<PlanProps> = ({ plan }): ReactElement => {
  const { typeOfPlan, paymentPlan, totalDescription, headerValue, imageUrl } =
    plan;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const deletePremium = () => {
    dispatch(removePremiumItem(t('premiumRemoved')));
  };

  return (
    <PlanWrap>
      <S.ProductCardWrap>
        <S.ProductCardInnerWrap>
          <S.DeleteIconWrap>
            <Popconfirm
              title={t('areYouSure')}
              onConfirm={deletePremium}
              icon={<QuestionCircleOutlined style={{ color: 'indianred' }} />}
            >
              <DeleteOutlined />
            </Popconfirm>
          </S.DeleteIconWrap>
          <S.ImageWrap>
            <S.Image src={imageUrl} />
          </S.ImageWrap>
          <S.InfoWrap>
            <S.DiaryInfo>
              <S.TitleWrap>
                <H4>{headerValue}</H4>
                <S.DeleteIconWrapMobile>
                  <Popconfirm
                    placement="leftBottom"
                    title={t('areYouSure')}
                    cancelText={t('cancel')}
                    onConfirm={deletePremium}
                    icon={
                      <QuestionCircleOutlined style={{ color: 'indianred' }} />
                    }
                  >
                    <DeleteOutlined />
                  </Popconfirm>
                </S.DeleteIconWrapMobile>
              </S.TitleWrap>
              <S.DiaryOverviewWrap>
                <Paragraph>
                  {typeOfPlan.label}: {typeOfPlan.value}
                </Paragraph>
                <Paragraph>
                  {paymentPlan.label}: {paymentPlan.value}
                </Paragraph>
                <Divider />
                <S.PriceQuantityWrap>
                  <Paragraph>
                    {totalDescription.label} : {totalDescription.value}
                  </Paragraph>
                </S.PriceQuantityWrap>
              </S.DiaryOverviewWrap>
            </S.DiaryInfo>
          </S.InfoWrap>
        </S.ProductCardInnerWrap>
      </S.ProductCardWrap>
    </PlanWrap>
  );
};

export default Plan;
