import * as React from "react"
import { IconType } from "lib/types"
import IconContainer from "components/Common/IconContainer"

function Instagram({ color, height, width }: IconType) {
  return (
    <IconContainer
      width={width}
      height={height}
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
      fillColor={color}
    >
      <path
        d="M373.406 0H138.594C62.172 0 0 62.172 0 138.594V373.41C0 449.828 62.172 512 138.594 512H373.41C449.828 512 512 449.828 512 373.41V138.594C512 62.172 449.828 0 373.406 0zm108.578 373.41c0 59.867-48.707 108.574-108.578 108.574H138.594c-59.871 0-108.578-48.707-108.578-108.574V138.594c0-59.871 48.707-108.578 108.578-108.578H373.41c59.867 0 108.574 48.707 108.574 108.578V373.41z"
        fill={color}
      />
      <path
        d="M256 116.004c-77.195 0-139.996 62.801-139.996 139.996 0 77.195 62.801 139.996 139.996 139.996 77.195 0 139.996-62.801 139.996-139.996 0-77.195-62.801-139.996-139.996-139.996zm0 249.976c-60.641 0-109.98-49.335-109.98-109.98 0-60.641 49.339-109.98 109.98-109.98 60.645 0 109.98 49.339 109.98 109.98 0 60.645-49.335 109.98-109.98 109.98zM399.344 66.285c-22.813 0-41.367 18.559-41.367 41.367 0 22.813 18.554 41.371 41.367 41.371 22.812 0 41.371-18.558 41.371-41.371 0-22.812-18.559-41.367-41.371-41.367zm0 52.719c-6.258 0-11.352-5.094-11.352-11.352 0-6.261 5.094-11.351 11.352-11.351 6.261 0 11.355 5.09 11.355 11.351 0 6.258-5.094 11.352-11.355 11.352z"
        fill={color}
      />
    </IconContainer>
  )
}

export default Instagram
