import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const common_en = require(`./locales/${process.env.REACT_APP_FILE_PREFIX}/en/common.json`);
const common_nl = require(`./locales/${process.env.REACT_APP_FILE_PREFIX}/nl/common.json`);
const common_es = require(`./locales/${process.env.REACT_APP_FILE_PREFIX}/es/common.json`);

const options = {
  // order and from where user language should be detected
  order: ['cookie', 'path'],

  // keys or params to lookup language from
  lookupCookie: 'i18next',
  lookupFromPathIndex: 0,

  // cache user language on
  caches: ['cookie'],
  excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

  // optional expire and domain for set cookie
  cookieMinutes: 360,
  cookieDomain: process.env.REACT_APP_DOMAIN || 'traveldiariesapp.com',
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: options,
    resources: {
      en: {
        common: common_en,
      },
      nl: {
        common: common_nl,
      },
      es: {
        common: common_es,
      },
    },
    fallbackLng: 'en',
    debug: true,
    defaultNS: 'common',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
