import React, { useState, useEffect, ReactElement } from "react";
import { Input } from "antd";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import styled from "styled-components/macro";

const QuantityPickerWrap = styled.div`
	width: 100%;
	max-width: 6em;
	display: inline-block;
	margin-left: 0.5em;
	position: relative;

	input {
		height: 1.9em;
		text-align: center;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		pointer-events: none;
		cursor: default;

		&:focus,
		&:active {
			border-color: rgb(228, 228, 228) !important;
		}
	}

	button {
		position: absolute;
		top: 0;
		bottom: 0;
		background: #dbdbdb;
		z-index: 5;
		color: #ffffff;
		width: 1.5em;
		display: flex;
		font-weight: bold;
		font-size: 20px;
		align-items: center;
		justify-content: center;
		border: 1px solid #e4e4e4;
		cursor: pointer;
		-webkit-transition: 0.15s background ease-in-out;
		-moz-transition: 0.15s background ease-in-out;
		transition: 0.15s background ease-in-out;

		&:disabled {
			user-select: none;
			cursor: not-allowed;

			&:hover {
				background: #dbdbdb;
			}
		}

		&:hover {
			background: #bdbdbd;
		}

		&:active,
		&:focus {
			outline: none;
		}

		.anticon {
			font-size: 0.7em;
		}
	}
`;

const DecrementButton = styled.button`
	-webkit-border-top-left-radius: 0.1em;
	-moz-border-top-left-radius: 0.1em;
	border-top-left-radius: 0.1em;
	-webkit-border-bottom-left-radius: 0.1em;
	-moz-border-bottom-left-radius: 0.1em;
	border-bottom-left-radius: 0.1em;
	left: 0;
`;

const IncrementButton = styled.button`
	-webkit-border-top-right-radius: 0.1em;
	-moz-border-top-right-radius: 0.1em;
	border-top-right-radius: 0.1em;
	-webkit-border-bottom-right-radius: 0.1em;
	-moz-border-bottom-right-radius: 0.1em;
	border-bottom-right-radius: 0.1em;
	right: 0;
`;

type QuantityPickerType = {
	setQuantityRequest: (diaryId: string, quantity: number) => void;
	maxQuantity: number;
	currentQuantity: number;
	id: string;
};

const QuantityPicker: React.FC<QuantityPickerType> = ({
	setQuantityRequest,
	maxQuantity,
	currentQuantity,
	id,
}): ReactElement => {
	const [quantity, setQuantity] = useState<number>(1);

	useEffect(() => {
		if (currentQuantity) {
			setQuantity(currentQuantity);
		}
	}, [currentQuantity]);

	const incrementQuantity = () => {
		if (quantity === maxQuantity) return;
		setQuantity((prevState: any) => {
			return prevState + 1;
		});

		setQuantityRequest(id, quantity + 1);
	};

	const decrementQuantity = () => {
		if (quantity <= 1) return;
		setQuantity((prevState: any) => {
			return prevState - 1;
		});

		setQuantityRequest(id, quantity - 1);
	};

	return (
		<QuantityPickerWrap>
			<DecrementButton onClick={decrementQuantity} disabled={quantity === 1}>
				<MinusOutlined />
			</DecrementButton>
			<Input value={quantity} />
			<IncrementButton onClick={incrementQuantity} disabled={quantity === maxQuantity}>
				<PlusOutlined />
			</IncrementButton>
		</QuantityPickerWrap>
	);
};

export default QuantityPicker;
