import { AddressType, MollieAddressType } from 'lib/types';
import { createAddressSchema } from './addressSchema';

export const convertAddressTypeToMollieeAddress = (
  a: AddressType
): MollieAddressType => ({
  organizationName: a?.company ?? '',
  phoneNumber: a?.phoneNumber ?? '',
  streetAndNumber: a.street,
  streetAdditional: a.houseNumber,
  city: a.city,
  region: '',
  postalCode: a.zipCode.toString(),
  country: a.country,
  title: '',
  givenName: a.firstName,
  familyName: a.lastName,
  stateCode: a.state,
});

export const validateAddress = (addressSchema, addressInfo) => {
  try {
    addressSchema.parse(addressInfo);
    return true;
  } catch (error) {
    return false;
  }
};

export const validateAddresses = (
  withDeliveryAddress: boolean,
  invoiceAddress: AddressType,
  deliveryAddress: AddressType
): boolean => {
  const invoiceAddressSchema = createAddressSchema(invoiceAddress.hasState);
  const deliveryAddressSchema = createAddressSchema(deliveryAddress.hasState);

  const isValidInvoice = validateAddress(invoiceAddressSchema, invoiceAddress);

  const isValidDelivery = validateAddress(
    deliveryAddressSchema,
    deliveryAddress
  );

  //If the invoice address is different from the delivery address, we need to validate both
  if (withDeliveryAddress) {
    return !!isValidDelivery && !!isValidInvoice;
  }
  //If the delivery address is the same as the invoice address, we only need to validate the invoice address
  return !!isValidInvoice;
};

export const decodeURLParams = (search = '') => {
  const hashes = search.slice(search.indexOf('?') + 1).split('&');
  return hashes.reduce((acc, hash) => {
    // eslint-disable-next-line
    const [key, val] = hash.split('=');
    return {
      ...acc,
      [key]: decodeURIComponent(val),
    };
  }, {});
};
