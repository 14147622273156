import React, { useEffect, ReactElement } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from 'store/reducers/rootReducer';
import { getCartItems } from 'store/actions/cart-actions';
import { message } from 'antd';
import { isEmpty as _isEmpty } from 'lodash';
import LoadingScreen from 'components/Common/LoadingScreen';
import PremiumPriceDetails from 'components/Views/PremiumPlanView/PlanPriceDetails';
import Plan from 'components/Views/PremiumPlanView/Plan';
import * as S from 'components/Stepper/styles';
import { H3 } from 'components/Common/Text';
import { Button } from 'components/Common/Button';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

export const ItemsCTAWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 11.5% 0;
  flex-direction: column;
  width: 100%;
  button {
    margin-top: 24px;
  }
`;

const premiumPath = {
  en: 'premium-plans',
  nl: 'premium-abonnement',
  es: 'premium-plans',
};

const PremiumStepThree: React.FC = (): ReactElement => {
  const dispatch = useDispatch();
  const { user, invoiceInfo } = useSelector((state: AppState) => state.user);
  const { t, i18n } = useTranslation();

  const { plan, errorPremium } = useSelector(
    (state: AppState) => state.premiumPlan
  );
  const { paymentType, isOrderSending } = useSelector(
    (state: AppState) => state.checkout
  );

  const premiumPlan = !!plan.id
    ? plan
    : JSON.parse(localStorage.getItem('premiumPlan') || '{}');

  useEffect(() => {
    dispatch(getCartItems(false));
    window.scrollTo(0, 0);
  }, [dispatch]);

  useEffect(() => {
    if (!_isEmpty(errorPremium)) {
      message.error({
        content: errorPremium,
        style: {
          marginTop: '75px',
        },
      });
    }
  }, [errorPremium]);

  const redirectDashboard = (endpoint: string) => {
    window.location.href = `${process.env.REACT_APP_WEBSITE_URL}/${
      i18n.language
    }/${premiumPath[i18n.language]}`;
  };

  if (_isEmpty(user) || isOrderSending) {
    return <LoadingScreen />;
  }

  if (_isEmpty(premiumPlan)) {
    return (
      <ItemsCTAWrap>
        <H3>{t('emptyCart')}</H3>
        <Button
          size='medium'
          filled
          onClick={() => redirectDashboard('dashboard')}
        >
          {t('goBackToPremiumPlans')}
        </Button>
      </ItemsCTAWrap>
    );
  }

  return (
    <S.MainViewComponent>
      <Plan plan={premiumPlan} />
      <PremiumPriceDetails
        plan={premiumPlan}
        invoiceInfo={invoiceInfo}
        paymentType={paymentType}
        type='checkout'
      />
    </S.MainViewComponent>
  );
};

export default PremiumStepThree;
