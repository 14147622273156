import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Callback from 'hoc/Callback';
import GuardRoute from 'hoc/GuardRoute';

import ProductsView from 'views/Products/ProductsView';
import ProductStepView from 'views/Products/ProductStepView';
import NotFoundView from 'views/NotFoundView';
import PremiumPlansView from 'views/PremiumPlans/PremiumPlansView';
import PremiumStepView from 'views/PremiumPlans/PremiumStepView';
import { routeLangs } from 'util/languages';
import SuccessView from 'views/SuccessView';
import { track } from 'hoc/CustomerIO';
import { GTMOrderSuccess } from 'util/gtm';

const productSuccessContent = {
  thankYou: 'successThankYou',
  willEmailYou: 'successWillEmailYou',
  continueButtonLabel: 'successBtnContinueJourney',
  whatNext: 'successWhatNext',
  nextSteps: 'successNextSteps',
};

const premiumSuccessContent = {
  thankYou: 'premiumSuccessThankYou',
  willEmailYou: 'premiumSuccessWillEmailYou',
  continueButtonLabel: 'premiumSuccessBtnContinueJourney',
  whatNext: 'premiumSuccessWhatNext',
  nextSteps: 'premiumSuccessNextSteps',
};

const premiumDowngradeContent = {
  thankYou: 'downgradeSuccessThankYou',
  willEmailYou: 'downgradeSuccessWillEmailYou',
  continueButtonLabel: 'premiumSuccessBtnContinueJourney',
  whatNext: 'downgradeSuccessWhatNext',
  nextSteps: 'downgradeSuccessNextSteps',
};

const premiumUpgradeContent = {
  thankYou: 'upgradeSuccessThankYou',
  willEmailYou: 'upgradeSuccessWillEmailYou',
  continueButtonLabel: 'premiumSuccessBtnContinueJourney',
  whatNext: 'upgradeSuccessWhatNext',
  nextSteps: 'upgradeSuccessNextSteps',
};

const Routes = () => {
  const { i18n } = useTranslation();
  const lang = i18n.language.toLowerCase();

  return (
    <Switch>
      <Route exact path="/signin-oidc" component={Callback} />
      <Redirect exact from="/" to={`/${lang}/cart`} />
      <Route
        exact
        path={`/:lang(${routeLangs})/cart`}
        render={(routeProps) => (
          <GuardRoute routeProps={routeProps} component={ProductsView} />
        )}
      />
      <Route
        exact
        path={`/:lang(${routeLangs})/get-premium/:id/:type`}
        render={(routeProps) => (
          <GuardRoute routeProps={routeProps} component={PremiumPlansView} />
        )}
      />
      <Route
        exact
        path={`/:lang(${routeLangs})/premium/:step(1|2|3)?`}
        render={(routeProps) => (
          <GuardRoute routeProps={routeProps} component={PremiumStepView} />
        )}
      />
      <Route
        exact
        path={`/:lang(${routeLangs})/checkout/:step(1|2|3)?`}
        render={(routeProps) => (
          <GuardRoute routeProps={routeProps} component={ProductStepView} />
        )}
      />
      <Route
        exact
        path={`/:lang(${routeLangs})/order-success/:orderNumber/:orderAmount/:orderCurrency`}
        render={(routeProps) => {
          return (
            <GuardRoute
              routeProps={routeProps}
              component={(props) => {
                return (
                  <SuccessView
                    {...props}
                    content={productSuccessContent}
                    callback={(
                      orderNumber: number,
                      orderAmount: string,
                      orderCurrency: string
                    ) => {
                      track('Product Checkout Paid', {
                        orderNumber,
                        orderAmount,
                        orderCurrency,
                      });
                      GTMOrderSuccess(orderNumber, orderAmount, orderCurrency);
                    }}
                  />
                );
              }}
            />
          );
        }}
      />
      <Route
        exact
        path={`/:lang(${routeLangs})/premium-success/:orderNumber/:orderAmount/:orderCurrency`}
        render={(routeProps) => (
          <GuardRoute
            routeProps={routeProps}
            component={(props) => {
              return (
                <SuccessView
                  {...props}
                  content={premiumSuccessContent}
                  callback={(
                    orderNumber: number,
                    orderAmount: string,
                    orderCurrency: string
                  ) => {
                    track('Premium Checkout Paid', {
                      orderNumber,
                      orderAmount,
                      orderCurrency,
                    });
                  }}
                />
              );
            }}
          />
        )}
      />
      <Route
        exact
        path={`/:lang(${routeLangs})/premium-upgrade/:orderNumber/:orderAmount/:orderCurrency`}
        render={(routeProps) => {
          return (
            <GuardRoute
              routeProps={routeProps}
              component={(props) => {
                return (
                  <SuccessView {...props} content={premiumUpgradeContent} />
                );
              }}
            />
          );
        }}
      />
      <Route
        exact
        path={`/:lang(${routeLangs})/premium-downgrade`}
        render={(routeProps) => {
          return (
            <GuardRoute
              routeProps={routeProps}
              component={(props) => {
                return (
                  <SuccessView {...props} content={premiumDowngradeContent} />
                );
              }}
            />
          );
        }}
      />
      <Route path="*" component={NotFoundView} />
    </Switch>
  );
};

export default Routes;
