/**
 * Calls the specified function repeatedly for a specified number of times with a specified time interval.
 * Stops calling the function if it returns true or if the specified number of times has been reached.
 *
 * @param func The function to call, returning a boolean value indicating whether to continue calling it.
 * @param n The number of times to call the function.
 * @param interval The time interval between each function call, in seconds.
 */
const repeater = (func: Function, n: number, interval: number): void => {
  let count = 0;
  const intervalId = setInterval(() => {
    count++;
    if (!func() && count !== n) {
      return;
    }
    clearInterval(intervalId);
  }, interval * 1000);
};

export { repeater };
