import React, { useEffect, ReactElement } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from 'store/reducers/rootReducer';
import { Radio, Select } from 'antd';
import * as S from 'components/Stepper/styles';
import {
  changePaymentChoices,
  updatePaymentType,
  updateIssuer,
} from 'store/actions/checkout-actions';
import Loader from 'components/Common/LoadingScreen';

const { Option } = Select;

// Styled Components

const ProductStepTwo: React.FC = (): ReactElement => {
  const { deliveryInfo, invoiceInfo } = useSelector(
    (state: AppState) => state.user
  );
  const { paymentType, paymentChoices, isLoading, issuer } = useSelector(
    (state: AppState) => state.checkout
  );
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const country = invoiceInfo.country ?? deliveryInfo.country;
    if (!country) return;
    dispatch(changePaymentChoices(country));
  }, [deliveryInfo, invoiceInfo, dispatch]);

  useEffect(() => {
    if (paymentChoices.length && !isLoading) {
      if (paymentType) {
        const found = paymentChoices.find((p) => p.id === paymentType);

        if (!found) {
          dispatch(updatePaymentType(null));
          dispatch(updateIssuer(null));
        }
      } else if (issuer) {
        dispatch(updateIssuer(null));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentChoices, isLoading]);

  const changePaymentType = (paymentId: string) => {
    const findType = paymentChoices.find((p) => p.id === paymentId);
    let issuerId: string | null = null;

    if (findType && findType.issuers && findType.issuers.length) {
      issuerId = findType.issuers[0].id;
    }

    dispatch(updatePaymentType(paymentId));
    dispatch(updateIssuer(issuerId));
  };

  return (
    <S.StepTwoWrap>
      {!isLoading ? (
        <S.PaymentRadioGroupWrap>
          <Radio.Group
            onChange={({ target: { value } }) => changePaymentType(value)}
            value={paymentType}
          >
            {paymentChoices.length
              ? paymentChoices.map((p) => (
                  <S.PaymentsRadioGroup key={p.id}>
                    <Radio value={p.id}>
                      <S.PaymentImage src={p.image} />
                      <S.PaymentSpan>{p.name}</S.PaymentSpan>
                    </Radio>
                    {p.issuers && p.issuers.length ? (
                      <S.PaymentSelectWrap>
                        {paymentType !== p.id ? (
                          <Select disabled={true}></Select>
                        ) : (
                          <Select
                            onChange={(value) => dispatch(updateIssuer(value))}
                            value={issuer || undefined}
                          >
                            {p.issuers.map((i) => (
                              <Option value={i.id} key={i.id}>
                                {i.name}
                              </Option>
                            ))}
                          </Select>
                        )}
                      </S.PaymentSelectWrap>
                    ) : null}
                  </S.PaymentsRadioGroup>
                ))
              : null}
          </Radio.Group>
        </S.PaymentRadioGroupWrap>
      ) : (
        <Loader />
      )}
    </S.StepTwoWrap>
  );
};

export default ProductStepTwo;
