import { put, takeLatest, call, all } from 'redux-saga/effects';
import axios from 'axios';
import {
  GET_PREMIUM_START,
  GET_PREMIUM_ERROR,
  GET_PREMIUM_START_SAGA,
  GET_PREMIUM_SUCCESS,
  IGetPremiumStartSagaAction,
  SEND_PREMIUM_ORDER_START,
  SEND_PREMIUM_ORDER_START_SAGA,
  ISendPremiumStartSagaAction,
  SEND_PREMIUM_ORDER_SUCCESS,
  SEND_PREMIUM_ORDER_ERROR,
} from 'store/actions/premium-plan-actions/premium-plan-action-types';
import i18n from 'i18n';

// Sagas
export function* getPremiumPlan(action: IGetPremiumStartSagaAction) {
  const { type, id, voucherCode } = action.payload;
  try {
    yield put({ type: GET_PREMIUM_START });
    const {
      data: { data },
    } = yield call(
      axios.get,
      voucherCode === undefined || voucherCode.length < 1
        ? `v1/me/membership/${id}/${type}`
        : `v1/me/membership/${id}/${type}/${voucherCode}`
    );

    yield put({
      type: GET_PREMIUM_SUCCESS,
      payload: { ...data, id, type },
    });
  } catch (err: Error | any) {
    console.log('PREMIUM PLANS ERROR >>', err.response);

    yield put({
      type: GET_PREMIUM_ERROR,
      payload: err?.response?.status,
    });
  }
}
const redirectToPayment = (extURL: string) => (window.location.href = extURL);

// Sagas
export function* sendPremiumOrderPlan(action: ISendPremiumStartSagaAction) {
  try {
    yield put({ type: SEND_PREMIUM_ORDER_START });

    const {
      data: { data },
    } = yield call(
      axios.post,
      `${process.env.REACT_APP_SERVICES_URL}/orders/v1/createPremium`,
      action.payload
    );

    yield put({ type: SEND_PREMIUM_ORDER_SUCCESS });
    yield call(redirectToPayment, data.checkoutUrl);
  } catch (err) {
    console.log('Premium order error >>', err.response);
    yield put({
      type: SEND_PREMIUM_ORDER_ERROR,
      payload: i18n.t('premiumOrderError'),
    });
  }
}

// Watchers
export function* watchGetPremiumPlan() {
  yield takeLatest(GET_PREMIUM_START_SAGA, getPremiumPlan);
}

export function* watchSendPremiumOrderPlan() {
  yield takeLatest(SEND_PREMIUM_ORDER_START_SAGA, sendPremiumOrderPlan);
}

// Export
export default function* premiumPlanSagas() {
  yield all([watchGetPremiumPlan(), watchSendPremiumOrderPlan()]);
}
