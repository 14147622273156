import React, { useEffect, ReactElement } from 'react';
import { RouterProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AppState } from 'store/reducers/rootReducer';
import { useDispatch } from 'react-redux';
import { getCartItems } from 'store/actions/cart-actions';
import { Container } from 'components/Common/Layout';
import { Button } from 'components/Common/Button';
import { message } from 'antd';
import { isEmpty as _isEmpty } from 'lodash';
import LoadingScreen from 'components/Common/LoadingScreen';
import styled from 'styled-components/macro';
import Products from 'components/Views/ProductView/Products';
import PriceDetails from 'components/Views/ProductView/PriceDetails';

// Styled Components

const MainViewComponent = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: -1.5em;
  margin-right: -1.5em;
  -webkit-animation: 0.45s fadeIn ease-in-out;
  -moz-animation: 0.45s fadeIn ease-in-out;
  animation: 0.45s fadeIn ease-in-out;

  @media (max-width: 992px) {
    flex-direction: column;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    flex-wrap: wrap;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const Heading = styled.h1`
  font-size: 4em;
  line-height: 1.2em;
  font-weight: 700;
  text-align: left;
  margin-bottom: 1.5em;

  @media (max-width: 768px) {
    text-align: center;
    font-size: 3em;
  }
`;

const PlaceOrderCta = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 10em;
`;

// Types

interface ProductsViewProps extends RouterProps {}

const ProductsView: React.FC<ProductsViewProps> = ({
  history,
}): ReactElement => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useSelector((state: AppState) => state.user);
  const { diariesInBasket, receipt, deletedItem, error, isLoading } =
    useSelector((state: AppState) => state.cart);

  useEffect(() => {
    dispatch(getCartItems(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!_isEmpty(deletedItem)) {
      message.success({
        content: t('cartItemRemoved', { deletedItem }),
        style: {
          marginTop: '45px',
        },
      });
    }
  }, [deletedItem, t]);

  useEffect(() => {
    if (!_isEmpty(error)) {
      message.error({
        content: error,
        style: {
          marginTop: '75px',
        },
      });
    }
  }, [error]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [dispatch]);

  return _isEmpty(user) || isLoading ? (
    <LoadingScreen />
  ) : (
    <Container>
      <Heading>{t('shoppingCart')}</Heading>
      <MainViewComponent>
        <Products diariesInBasket={diariesInBasket} />
        <PriceDetails
          receipt={receipt}
          diariesInBasket={diariesInBasket}
          type="main"
        />
      </MainViewComponent>
      {diariesInBasket?.length ? (
        <PlaceOrderCta>
          <Button
            filled
            size="medium"
            onClick={() => history.push(`/${i18n.language}/checkout/1`)}
          >
            {t('placeOrder')}
          </Button>
        </PlaceOrderCta>
      ) : null}
    </Container>
  );
};

export default ProductsView;
