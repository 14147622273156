import { combineReducers } from 'redux';

import UserReducer from 'store/reducers/user-reducer';
import CartReducer from 'store/reducers/cart-reducer';
import PremiumPlanReducer from 'store/reducers/premium-plan-reducer';
import CheckoutReducer from 'store/reducers/checkout-reducer';

const rootReducer = combineReducers({
  user: UserReducer,
  cart: CartReducer,
  checkout: CheckoutReducer,
  premiumPlan: PremiumPlanReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;
