import React, { useEffect, ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from 'store/reducers/rootReducer';
import { H4, Paragraph } from 'components/Common/Text';
import styled from 'styled-components/macro';
import {
  updateDeliveryInfo,
  updateInvoiceInfo,
  getCountries,
  setWithInvoiceAddress,
} from 'store/actions/user-actions';
import {
  getShippingPrice,
  sendShipmentTypeChange,
  setShipmentError,
} from 'store/actions/checkout-actions';
import { Checkbox, message } from 'antd';
import { isEmpty } from 'lodash';
import { ExclamationCircleOutlined } from '@ant-design/icons';

// StyledComponents

import * as S from 'components/Stepper/styles';
import ShipmentWarningModal from 'components/Modals/ShipmentWarningModal';
import { AddressForm } from './AddressForm';
import { updateReceipt } from 'store/actions/cart-actions';

const DeliveryAddressCheckboxWrap = styled.div`
  padding: 5em 0 0;
  ${({ deliveryAddress }) =>
    deliveryAddress && {
      padding: '5em 0',
    }}
`;

const InvoiceAddress = styled.div`
  display: flex;
  flex-direction: column;
  -webkit-animation: 0.45s fadeIn linear;
  -moz-animation: 0.45s fadeIn linear;
  animation: 0.45s fadeIn linear;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const TotalPriceWrap = styled.div`
  margin: 4em 0 3em 0;

  b {
    font-size: 1.2em;
    padding-left: 1em;
  }
  p {
    display: flex;
    align-content: center;
  }
`;

const ShipmentMessageWrapper = styled.div`
  margin-top: 3em;
  display: flex;

  .anticon {
    color: ${({ theme }) => theme.primaryColor};
    display: inline-block;
    margin-left: 0.5em;
    position: relative;
    font-size: 17px;
  }
  span {
    margin-left: 0;
    margin-right: 1em;
    align-items: center;
  }
  p {
    font-size: 1.25em;
    font-weight: 600;
  }
`;

const ProductStepOne: React.FC = (): ReactElement => {
  const { t } = useTranslation();
  const {
    withDeliveryAddress,
    deliveryInfo,
    invoiceInfo,
    countries,
    isCountriesLoading,
  } = useSelector((state: AppState) => state.user);

  const { shippingPrice, errorCheckout, shipmentError } = useSelector(
    (state: AppState) => state.checkout
  );
  const { diariesInBasket } = useSelector((state: AppState) => state.cart);
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!countries.length && !isCountriesLoading) dispatch(getCountries());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const info = withDeliveryAddress ? deliveryInfo : invoiceInfo;
    dispatch(sendShipmentTypeChange(info.state, info.country));
  }, [dispatch, withDeliveryAddress, deliveryInfo, invoiceInfo]);

  const resetShipmentError = () => {
    dispatch(setShipmentError(null));
    dispatch(updateDeliveryInfo({ ...deliveryInfo, country: '' }));
    dispatch(updateInvoiceInfo({ ...invoiceInfo, country: '' }));
  };

  useEffect(() => {
    if (!isEmpty(errorCheckout)) {
      message.error({
        content: errorCheckout,
        style: {
          marginTop: '75px',
        },
      });
    }
  }, [errorCheckout]);

  const updateDelivery = (e: React.ChangeEvent<HTMLInputElement>) =>
    dispatch(
      updateDeliveryInfo({ ...deliveryInfo, [e.target.name]: e.target.value })
    );

  const updateInvoice = (e: React.ChangeEvent<HTMLInputElement>) =>
    dispatch(
      updateInvoiceInfo({ ...invoiceInfo, [e.target.name]: e.target.value })
    );

  const { deliveryCountry, deliveryState, hasState } = useMemo(() => {
    if (withDeliveryAddress) {
      return {
        deliveryCountry: deliveryInfo.country,
        hasState: deliveryInfo.hasState,
        deliveryState: deliveryInfo.state,
      };
    }
    return {
      deliveryCountry: invoiceInfo.country,
      deliveryState: invoiceInfo.state,
      hasState: invoiceInfo.hasState,
    };
  }, [invoiceInfo, deliveryInfo, withDeliveryAddress]);

  useEffect(() => {
    // If the country has a state, we need to make sure deliveryState is not empty
    if (!deliveryCountry) return;
    if ((hasState && deliveryState) || !hasState) {
      dispatch(getShippingPrice(deliveryCountry, deliveryState));
      dispatch(updateReceipt(deliveryCountry, deliveryState));
    }
  }, [deliveryCountry, deliveryState, dispatch, hasState]);

  return (
    <S.StepOneWrap>
      <H4>
        {t('enterAddress', {
          title: t('invoice'),
        })}
      </H4>
      <AddressForm
        key="2"
        type="invoice"
        address={invoiceInfo}
        onChange={updateInvoice}
      />
      <DeliveryAddressCheckboxWrap deliveryAddress={withDeliveryAddress}>
        <Checkbox
          checked={withDeliveryAddress}
          onChange={() => dispatch(setWithInvoiceAddress())}
        >
          {t('differentDeliveryAddress')}
        </Checkbox>
      </DeliveryAddressCheckboxWrap>
      {withDeliveryAddress && (
        <InvoiceAddress>
          <H4>
            {t('enterAddress', {
              title: t('delivery'),
            })}
          </H4>
          <AddressForm
            type="delivery"
            address={deliveryInfo}
            onChange={updateDelivery}
          />
        </InvoiceAddress>
      )}
      <TotalPriceWrap>
        <Paragraph>
          {t('shippingCost')}:
          {shippingPrice && <b>€ {Number(shippingPrice).toFixed(2)}</b>}
        </Paragraph>
      </TotalPriceWrap>
      <ShipmentMessageWrapper>
        <ExclamationCircleOutlined style={{ marginLeft: '8px' }} />
        <Paragraph>{t('yourOrderWillBeShipped')} </Paragraph>
      </ShipmentMessageWrapper>
      <ShipmentWarningModal
        diariesInBasket={diariesInBasket}
        error={shipmentError}
        onConfirm={resetShipmentError}
      />
    </S.StepOneWrap>
  );
};

export default ProductStepOne;
