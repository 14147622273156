// Styled Components

import styled from 'styled-components/macro';

export const ProductCardWrap = styled.div`
  border: 1px solid #ededed;
  -webkit-box-shadow: rgb(227, 227, 227) 1em 1em 2em 0px;
  -moz-box-shadow: rgb(227, 227, 227) 1em 1em 2em 0px;
  box-shadow: rgb(227, 227, 227) 1em 1em 2em 0px;
  -webkit-border-radius: 1.5em;
  -moz-border-radius: 1.5em;
  border-radius: 1.5em;
  margin-bottom: 3em;
`;

export const ProductCardInnerWrap = styled.div`
  padding: 1em;
  display: flex;
  flex-direction: row;
  position: relative;

  @media (max-width: 550px) {
    flex-direction: column;
  }
`;

export const ImageWrap = styled.div`
  width: 16em;
  height: auto;
  min-height: 20em;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  position: relative;

  p {
    margin-bottom: 0;
    text-align: center;
    font-size: 1.4em;
    margin-top: 5px;
  }

  @media (max-width: 550px) {
    width: 100%;
    height: auto;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: auto;
  margin: auto 0;
  object-fit: cover;
  -webkit-border-radius: 0.35em;
  -moz-border-radius: 0.35em;
  -webkit-transition: 0.75s all ease-in-out;
  -moz-transition: 0.75s all ease-in-out;
  transition: 0.75s all ease-in-out;

  @media (max-width: 600px) {
    width: 100%;
    height: 100%;
  }
`;

export const InfoWrap = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  padding: 1em 1.75em;

  @media (max-width: 550px) {
    position: relative;
    padding: 2em 1em;
  }
`;

export const DiaryInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const TitleWrap = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 3em;

  h4 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    margin-bottom: 0;
    width: 95%;
  }
`;

export const DiaryOverviewWrap = styled.div`
  margin-top: auto;

  p {
    margin-bottom: 0.75em;
    font-size: 1.6em;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

export const PriceQuantityWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const DeleteIconWrap = styled.div`
  position: absolute;
  right: 2.7em;
  top: 1.75em;
  border: 1px solid #ededed;
  -webkit-border-radius: 0.5em;
  -moz-border-radius: 0.5em;
  border-radius: 0.5em;
  cursor: pointer;
  -webkit-transition: 0.05s all linear;
  -moz-transition: 0.05s all linear;
  transition: 0.05s all linear;

  .anticon {
    color: indianred;
    font-size: 2em;
    padding: 0.3em;
  }

  &:hover {
    background: indianred;
    border-color: indianred;
    .anticon {
      color: white;
    }
  }

  @media (max-width: 992px) {
    display: none;
  }
`;

export const DeleteIconWrapMobile = styled.div`
  border: 1px solid #ededed;
  -webkit-border-radius: 0.5em;
  -moz-border-radius: 0.5em;
  border-radius: 0.5em;
  cursor: pointer;
  -webkit-transition: 0.05s all linear;
  -moz-transition: 0.05s all linear;
  transition: 0.05s all linear;
  display: none;

  .anticon {
    color: indianred;
    font-size: 2em;
    padding: 0.3em;
  }

  &:hover {
    background: indianred;
    border-color: indianred;
    .anticon {
      color: white;
    }
  }

  @media (max-width: 992px) {
    display: block;
  }
`;

export const Quantity = styled.div`
  width: auto;
  font-size: 1.6em;
  position: relative;
  top: -2px;

  p {
    display: inline-block;
    font-size: 16px;
  }

  @media (max-width: 400px) {
    p {
      display: none;
    }
  }
`;
