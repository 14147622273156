import { all } from 'redux-saga/effects';
import allUserSagas from 'store/sagas/user-sagas';
import allCartSagas from 'store/sagas/cart-sagas';
import allCheckoutSagas from 'store/sagas/checkout-sagas';
import premiumPlanSagas from './premium-plan-sagas';

export default function* rootSaga() {
  yield all([
    allUserSagas(),
    allCartSagas(),
    allCheckoutSagas(),
    premiumPlanSagas(),
  ]);
}
