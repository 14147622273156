import {
  GET_CART_START,
  GET_CART_SUCCESS,
  GET_CART_ERROR,
  SET_CART_QUANTITY_START,
  SET_CART_QUANTITY_ERROR,
  SET_CART_QUANTITY_SUCCESS,
  CartActionTypes,
  DELETE_CART_ITEM_START,
  DELETE_CART_ITEM_SUCCESS,
  DELETE_CART_ITEM_ERROR,
  POST_VOUCHER_START,
  POST_VOUCHER_SUCCESS,
  POST_VOUCHER_ERROR,
  RESET_CART,
  REMOVE_VOUCHER_ERROR,
  REMOVE_VOUCHER_START,
  REMOVE_VOUCHER_SUCCESS,
  UPDATE_RECEIPT_START,
  UPDATE_RECEIPT_SUCCESS,
  UPDATE_RECEIPT_ERROR,
} from 'store/actions/cart-actions/cart-action-types';

import {
  DiaryProduct,
  CartReceiptType,
  DiscountType,
  VoucherUsageType,
} from 'lib/types';
import i18n from 'i18n';

interface ICart {
  diariesInBasket: DiaryProduct[] | any[];
  receipt: CartReceiptType;
  isLoading: boolean;
  error: null | string;
  deletedItem: string;
  voucherIsLoading: boolean;
  voucherError: string;
  voucherSuccess: string;
}

const initialState: ICart = {
  diariesInBasket: [],
  receipt: {
    shipmentCost: null,
    totalDiscount: {
      currencyCode: 'EUR',
      price: 0,
    },
    totalPrice: {
      currencyCode: 'EUR',
      price: 0,
    },
    discounts: [
      {
        name: 'EUR',
        amount: {
          currencyCode: 'EUR',
          price: 0,
        },
        type: DiscountType.DiscountVoucher,
        voucherUsageType: VoucherUsageType.canDelete,
      },
    ],
  },
  isLoading: false,
  deletedItem: '',
  error: null,
  voucherIsLoading: false,
  voucherError: '',
  voucherSuccess: '',
};

const CartReducer = (
  state: ICart = initialState,
  action: CartActionTypes
): ICart => {
  switch (action.type) {
    case GET_CART_START:
      return {
        ...state,
        isLoading: true,
      };
    case GET_CART_SUCCESS:
      return {
        ...state,
        diariesInBasket: action.payload.diariesInBasket,
        receipt: action.payload.receipt,
        isLoading: false,
      };
    case GET_CART_ERROR:
      return {
        ...state,
        isLoading: false,
        error: i18n.t('basketNotFound'),
      };
    case SET_CART_QUANTITY_START:
      return {
        ...state,
      };
    case SET_CART_QUANTITY_SUCCESS:
      return {
        ...state,
        diariesInBasket: action.payload.diariesInBasket,
        receipt: action.payload.receipt,
        isLoading: false,
      };
    case SET_CART_QUANTITY_ERROR:
      return {
        ...state,
        isLoading: false,
        error: i18n.t('basketNotFound'),
      };
    case DELETE_CART_ITEM_START:
      return {
        ...state,
        isLoading: true,
      };
    case DELETE_CART_ITEM_SUCCESS:
      return {
        ...state,
        diariesInBasket: action.payload.diariesInBasket,
        receipt: action.payload.receipt,
        deletedItem: action.payload.name,
        isLoading: false,
      };
    case DELETE_CART_ITEM_ERROR:
      return {
        ...state,
        isLoading: false,
        error: i18n.t('basketNotFound'),
      };

    case POST_VOUCHER_START:
      return {
        ...state,
        voucherIsLoading: true,
        voucherError: '',
        voucherSuccess: '',
      };
    case POST_VOUCHER_SUCCESS:
      return {
        ...state,
        voucherIsLoading: false,
        diariesInBasket: action.payload.diariesInBasket,
        receipt: action.payload.receipt,
        voucherSuccess: action.payload.successMessage,
        voucherError: '',
      };
    case POST_VOUCHER_ERROR:
      return {
        ...state,
        voucherIsLoading: false,
        voucherError: i18n.t(action.payload),
      };

    case REMOVE_VOUCHER_START:
      return {
        ...state,
        voucherIsLoading: true,
        voucherError: '',
        voucherSuccess: '',
      };
    case REMOVE_VOUCHER_SUCCESS:
      return {
        ...state,
        voucherIsLoading: false,
        diariesInBasket: action.payload.diariesInBasket,
        receipt: action.payload.receipt,
        voucherSuccess: '',
        voucherError: '',
      };
    case REMOVE_VOUCHER_ERROR:
      return {
        ...state,
        voucherIsLoading: false,
        voucherError: action.payload,
      };

    case RESET_CART:
      return {
        ...state,
        isLoading: false,
        deletedItem: '',
        voucherError: '',
        voucherSuccess: '',
        error: '',
      };

    case UPDATE_RECEIPT_START:
      return {
        ...state,
        isLoading: true,
      };
    case UPDATE_RECEIPT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        receipt: {
          ...action.payload,
          discounts: state.receipt.discounts,
        },
      };
    case UPDATE_RECEIPT_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default CartReducer;
