import React, { useEffect, ReactElement } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AppState } from 'store/reducers/rootReducer';
import { Container } from 'components/Common/Layout';
import { Button } from 'components/Common/Button';
import { Tooltip, message } from 'antd';
import { isEmpty as _isEmpty } from 'lodash';
import LoadingScreen from 'components/Common/LoadingScreen';
import styled from 'styled-components/macro';
import {
  getPremiumPlan,
  setPremiumPlan,
} from 'store/actions/premium-plan-actions';
import Plan from 'components/Views/PremiumPlanView/Plan';
import PremiumPriceDetails from 'components/Views/PremiumPlanView/PlanPriceDetails';
import { ItemsCTAWrap } from 'components/Stepper/styles';
import { H3 } from 'components/Common/Text';
import { getAccountDataAction } from 'store/actions/user-actions';
import { PremiumCreationType } from 'lib/types';
import { DowngradeAlreadyRequested } from './components/DowngradeAlreadyRequested';
import { ConfirmDowngradePlan } from './components/ConfirmDowngradePlan';
import { MobileSubscriptionPlan } from './components/MobileSubscriptionPlan';

const MainViewComponent = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: -1.5em;
  margin-right: -1.5em;
  -webkit-animation: 0.45s fadeIn ease-in-out;
  -moz-animation: 0.45s fadeIn ease-in-out;
  animation: 0.45s fadeIn ease-in-out;

  @media (max-width: 992px) {
    flex-direction: column;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    flex-wrap: wrap;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const Heading = styled.h1`
  font-size: 4em;
  line-height: 1.2em;
  font-weight: 700;
  text-align: left;
  margin-bottom: 1em;

  @media (max-width: 768px) {
    text-align: center;
    font-size: 3em;
  }
`;

const PlaceOrderCta = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 2em;

  @media (max-width: 768px) {
    margin-top: 5em;
  }
`;

const premiumPath = {
  en: 'premium-plans',
  nl: 'premium-abonnement',
  es: 'premium-plans',
};

// Types

interface MatchParams {
  id: string;
  type: string;
}

interface ProductsViewProps extends RouteComponentProps<MatchParams> {}

const PremiumPlansView: React.FC<ProductsViewProps> = ({
  history,
  match,
}): ReactElement => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { user, userAccount } = useSelector((state: AppState) => state.user);
  const { plan, isLoading, errorPremium, errorResponseCode } = useSelector(
    (state: AppState) => state.premiumPlan
  );
  const { id, type } = match.params;
  const lang = i18n.language.toLowerCase();

  const { premiumCreationType } = plan || {};

  const isMobileSubscription =
    userAccount.registrationSource === 'Android' ||
    userAccount.registrationSource === 'Apple';

  useEffect(() => {
    dispatch(getAccountDataAction());
    dispatch(getPremiumPlan(id, type));
  }, [dispatch, id, type]);

  useEffect(() => {
    if (plan) {
      dispatch(setPremiumPlan(plan));
    } else return;
  }, [dispatch, plan]);

  useEffect(() => {
    if (!_isEmpty(errorPremium)) {
      message.error({
        content: errorPremium,
        style: { marginTop: '75px' },
      });
    }
  }, [errorPremium]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const goToPremiumPlans = () => {
    window.location.href = `${process.env.REACT_APP_WEBSITE_URL}/${lang}/${premiumPath[lang]}`;
  };

  if (_isEmpty(user) || isLoading) {
    return <LoadingScreen />;
  }

  // If the user has already requested a downgrade, show the DowngradeAlreadyRequested component
  if (errorResponseCode === 409) {
    return <DowngradeAlreadyRequested />;
  }

  if (isMobileSubscription) {
    return <MobileSubscriptionPlan />;
  }
  // If user has a premium account and wants to downgrade, show the ConfirmDowngradePlan component
  if (
    userAccount?.renewDate &&
    premiumCreationType === PremiumCreationType.DOWN
  ) {
    return <ConfirmDowngradePlan />;
  }

  if (plan && plan.id) {
    return (
      <Container>
        <Heading>{t('shoppingCart')}</Heading>
        <MainViewComponent>
          <Plan plan={plan} />
          <PremiumPriceDetails plan={plan} type="main" />
        </MainViewComponent>
        <PlaceOrderCta>
          <Tooltip
            placement="bottom"
            title={
              premiumCreationType === PremiumCreationType.SAME
                ? t('hasSamePremium')
                : t('hasCancelledPlan')
            }
          >
            <span>
              <Button
                filled
                size="medium"
                disabled={
                  premiumCreationType === PremiumCreationType.SAME ||
                  (!userAccount?.renewDate &&
                    userAccount.premiumModelType !== 'Free')
                }
                onClick={() =>
                  history.push(`/${i18n.language}/premium/1`, {
                    state: {
                      id,
                      type,
                    },
                  })
                }
              >
                {t('placeOrder')}
              </Button>
            </span>
          </Tooltip>
        </PlaceOrderCta>
      </Container>
    );
  }

  return (
    <Container>
      <Heading>{t('shoppingCart')}</Heading>
      <ItemsCTAWrap>
        <H3>{t('emptyCart')}</H3>
        <Button size="medium" filled onClick={() => goToPremiumPlans()}>
          {t('goBackToPremiumPlans')}
        </Button>
      </ItemsCTAWrap>
    </Container>
  );
};

export default PremiumPlansView;
