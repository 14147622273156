import React, { ReactElement } from "react";
import Layout from "hoc/Layout";
import notFoundImage from "assets/images/404.png";
import styled from "styled-components/macro";


const { REACT_APP_NAME } = process.env;

const NotFoundWrap = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	@media (max-width: 768px) {
		img {
			height: auto;
			width: 100%;
		}
	}
`;

const PageNotFound: React.FC = (): ReactElement => {
	return (
		<Layout>
			<NotFoundWrap>
				<img src={notFoundImage} alt={`${REACT_APP_NAME} - Not Found`} />
			</NotFoundWrap>
		</Layout>
	);
};

export default PageNotFound;
