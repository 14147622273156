import {
  UserActionTypes,
  SET_USER,
  UPDATE_DELIVERY_INFO,
  UPDATE_INVOICE_INFO,
  GET_COUNTRIES_START_SAGA,
  SET_WITH_INVOICE_ADDRESS,
  GET_ACCOUNT_DATA_START_SAGA,
} from 'store/actions/user-actions/user-action-types';
import { UserType, AddressType } from 'lib/types';

export const setUserAction = (user: UserType): UserActionTypes => {
  return { type: SET_USER, payload: user };
};
export const updateDeliveryInfo = (info: AddressType): UserActionTypes => {
  localStorage.setItem('deliveryInfo', JSON.stringify(info));
  return { type: UPDATE_DELIVERY_INFO, payload: info };
};
export const updateInvoiceInfo = (info: AddressType): UserActionTypes => {
  localStorage.setItem('invoiceInfo', JSON.stringify(info));
  return { type: UPDATE_INVOICE_INFO, payload: info };
};
export const getCountries = (): UserActionTypes => {
  return { type: GET_COUNTRIES_START_SAGA };
};
export const setWithInvoiceAddress = (): UserActionTypes => {
  return { type: SET_WITH_INVOICE_ADDRESS };
};

export const getAccountDataAction = (): UserActionTypes => {
  return { type: GET_ACCOUNT_DATA_START_SAGA };
};
