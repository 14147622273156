import {
  GET_CART_START_SAGA,
  SET_CART_QUANTITY_START_SAGA,
  DELETE_CART_ITEM_START_SAGA,
  POST_VOUCHER_START_SAGA,
  REMOVE_VOUCHER_START_SAGA,
  UPDATE_RECEIPT_START_SAGA,
} from 'store/actions/cart-actions/cart-action-types';

export const getCartItems = (isPreview: boolean = false) => {
  return { type: GET_CART_START_SAGA, payload: isPreview };
};

export const setQuantityCartItem = (itemId: string, quantity: number) => {
  return { type: SET_CART_QUANTITY_START_SAGA, payload: { itemId, quantity } };
};

export const deleteCartItem = (itemId: string, name: string) => {
  return { type: DELETE_CART_ITEM_START_SAGA, payload: { itemId, name } };
};

export const postVoucher = (code: string, successMessage: string) => {
  return { type: POST_VOUCHER_START_SAGA, payload: { code, successMessage } };
};
export const deleteVoucher = (code: string, successMessage: string) => {
  return { type: REMOVE_VOUCHER_START_SAGA, payload: { code, successMessage } };
};

export const updateReceipt = (countryId: string, stateCode?: string) => {
  return {
    type: UPDATE_RECEIPT_START_SAGA,
    payload: { countryId, stateCode },
  };
};
