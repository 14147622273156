import React, { useEffect, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from 'store/reducers/rootReducer';
import { H4 } from 'components/Common/Text';
import { updateInvoiceInfo, getCountries } from 'store/actions/user-actions';
import * as S from 'components/Stepper/styles';
import { AddressForm } from 'views/Products/steps/AddressForm';

const PremiumStepOne: React.FC = (): ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { invoiceInfo, countries, isCountriesLoading } = useSelector(
    (state: AppState) => state.user
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!countries.length && !isCountriesLoading) dispatch(getCountries());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateInvoice = (e: React.ChangeEvent<HTMLInputElement>) =>
    dispatch(
      updateInvoiceInfo({ ...invoiceInfo, [e.target.name]: e.target.value })
    );

  return (
    <S.StepOneWrap>
      <H4>
        {t('enterAddress', {
          title: t('invoice'),
        })}
      </H4>
      <AddressForm
        address={invoiceInfo}
        onChange={updateInvoice}
        type="invoice"
      />
    </S.StepOneWrap>
  );
};

export default PremiumStepOne;
