import React, { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { H1, H2, H3, H4, Paragraph } from 'components/Common/Text';
import { Button, Anchor } from 'components/Common/Button';
import { Container } from 'components/Common/Layout';
import { CheckCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components/macro';
import { renderSocialIcons } from 'components/Footer';
import { appName } from 'hoc/Layout';
const success_image = require(`assets/images/${process.env.REACT_APP_FILE_PREFIX}/success_image.jpg`);

// Styled components
const Banner = styled.div`
  height: 50em;
  background: url(${success_image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 20%;
  -webkit-box-shadow: rgba(33, 33, 33, 0.2) 0px 0px 11px;
  -moz-box-shadow: rgba(33, 33, 33, 0.2) 0px 0px 11px;
  box-shadow: rgba(33, 33, 33, 0.2) 0px 0px 11px;
  border-radius: 2.5em;
  margin-top: -2em;

  @media (max-width: 500px) {
    height: 40em;
  }
`;

const OrderConfirmation = styled.div`
  padding: 6.5em 0 0em;
  text-align: center;

  .anticon {
    color: ${({ theme }) => theme.primaryColor};
    font-size: 8em;
    font-weight: 400;
  }
`;

const OrderConfirmationInfo = styled.div`
  padding: 5em 0 0 0;
  text-align: center;

  h1 {
    text-transform: uppercase;
    width: 80%;
    margin: 1em auto;
    @media (max-width: 480px) {
      font-size: 2.75em !important;
    }
  }

  h4 {
    font-weight: 300;
    color: ${({ theme }) => theme.greyColor};
  }

  button {
    margin: 4em auto;
    @media (max-width: 400px) {
      font-size: 1.3em;
    }
  }
`;

const OrderNextSteps = styled.div`
  padding: 0;
  text-align: center;

  h3 {
    font-size: 2.35em;
    &:last-of-type {
      margin: 3em 0;
      color: ${({ theme }) => theme.primaryColor};
    }
  }
  p {
    margin-bottom: 3em;
  }
`;

const Connected = styled.div`
  text-align: center;
  padding: 2em 0 0;

  h2 {
    text-transform: uppercase;
  }
`;

const ConnectedSocialMedia = styled.div`
  width: 100%;
  max-width: 40em;
  margin: 5em auto 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

type SuccessContent = {
  thankYou: string;
  whatNext: string;
  nextSteps: string;
  continueButtonLabel: string;
  willEmailYou: string;
};

type SuccessParams = {
  match: {
    params: {
      lang: string;
      orderNumber: number;
      orderAmount: string;
      orderCurrency: string;
    };
  };
  content: SuccessContent;
  callback: (
    orderNumber: number,
    orderAmount: string,
    orderCurrency: string
  ) => void;
  type: 'Premium' | 'Product';
};

const emailadress = {
  'Travel Diaries': 'support@traveldiariesapp.com',
  'Baby Diaries': 'support@babydiariesapp.com',
};

const SuccessView: React.FC<SuccessParams> = ({
  match: {
    params: { lang, orderNumber, orderAmount, orderCurrency },
  },
  content: { thankYou, whatNext, nextSteps, willEmailYou, continueButtonLabel },
  callback,
}): ReactElement => {
  const { t } = useTranslation();

  ['invoiceInfo', 'issuer', 'paymentType', 'premiumPlan'].forEach((k) =>
    localStorage.removeItem(k)
  );

  const handleRedirect = () =>
    window.location.replace(
      `${process.env.REACT_APP_DASHBOARD_URL}/${lang}/dashboard/`
    );

  useEffect(() => {
    callback?.(orderNumber, orderAmount, orderCurrency);
  }, [orderNumber, orderAmount, orderCurrency, callback]);

  return (
    <>
      <Container>
        <Banner />
        <OrderConfirmation>
          <CheckCircleOutlined />
          <OrderConfirmationInfo>
            {orderNumber && (
              <H4>
                {t('orderNumber')}: {orderNumber}
              </H4>
            )}
            <H1>{t(thankYou)}</H1>
            <Paragraph>{t(willEmailYou)}</Paragraph>
            <Button filled size="large" onClick={handleRedirect}>
              {t(continueButtonLabel)}
            </Button>
          </OrderConfirmationInfo>
          <OrderNextSteps>
            <H3>{t(whatNext)}</H3>
            <Paragraph>{t(nextSteps)}</Paragraph>
            <H3>{t('anyQuestions')}</H3>
            <Paragraph>
              {t('successContactUs')}
              <Anchor type="primary" href={`mailto:${emailadress[appName]}`}>
                {emailadress[appName]}
              </Anchor>
            </Paragraph>
            <H3>{t('successHaveFun')}</H3>
          </OrderNextSteps>
          <Connected>
            <H2>{t('successStayConnected')}</H2>
            <ConnectedSocialMedia>
              {renderSocialIcons('32', '32')}
            </ConnectedSocialMedia>
          </Connected>
        </OrderConfirmation>
      </Container>
    </>
  );
};

export default SuccessView;
